// Bundles

import { Stack, Typography, Box } from "@mui/material";
import useStore from "../store";
// import React from "react";
// import BundleSelection from './BundleSelection';
import { theme_textColorBlended, theme_textColorMain, theme_bgColorLight, theme_orange } from "../Theme";
//import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import NrrItem from "./NrrItem";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface BundlesProps
{
}

//-------------------------------------------------------------------------------
// Bundles display component
//-------------------------------------------------------------------------------
const Bundles = (props: BundlesProps) => 
{
  // Get needed state data from the store
  const { store_isLoggedIn, store_bundle, store_project, store_nrrs, 
          store_userProfile, store_bundleIsLoading, store_bundleListIsLoading, 
          store_userProfileIsLoading, store_projectIsLoading, 

        } = useStore();



  //-------------------------------------------------------------------------------
  // The user wants to change the active bundle.
  //-------------------------------------------------------------------------------
  // const onSwitchBundlesButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
  // {
  //   store_setBundleUIMode('select');
  // }





  // ******************************************************************************************
  // Main render - no user logged in
  // ******************************************************************************************

  if(!store_isLoggedIn) 
  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Please log in to see this section.
    </Box>
  )

  // ******************************************************************************************
  // Main render - a bundle is currently being loaded
  // ******************************************************************************************

  if(!store_userProfile || !store_project ||
     store_userProfileIsLoading || store_projectIsLoading || 
     store_bundleIsLoading || store_bundleListIsLoading)
  return (
    <Typography sx={{ fontSize: '1rem', color: theme_textColorMain, mb: 1, textTransform: 'capitalize' }}>
      Loading...
    </Typography>
  )

  // ******************************************************************************************
  // Main render - no bundle selected, or user is switching to a new bundle
  // ******************************************************************************************

  // if((store_project && store_project.bundle_id === null) || store_bundleUIMode === 'select')
  // return (
  //   <BundleSelection/>
  // )

  // ******************************************************************************************
  // Main render - a bundle is selected
  // ******************************************************************************************

  if(store_project && store_project.bundle_id !== null && store_bundle)
  return (

    <Stack direction='column' sx={{ ml: '-10px', width: 'calc(100% + 20px)', alignItems: 'left', mt: -1.5, mb: 2 }} >

      <Typography sx={{ fontSize: '1rem', color: theme_orange, mb: 1, textTransform: 'capitalize' }}>
        Land Management Options
      </Typography>

      {/* {DEV_MODE
        ?
          <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, mb: 1, textTransform: 'capitalize' }}>
            {store_nrrs.length} NRRs | {store_layers.length} layers | {store_nrrImpacts.length} impacts
          </Typography>
        :null
      } */}

      <Stack direction='column' sx={{ ml: '0px', width: '100%', bgcolor: theme_bgColorLight, borderRadius: 1 }}>

        {/* List of NRRs for the active bundle */}

        {store_nrrs.map(nrr => 
          <NrrItem key={nrr.nrr_id} nrr={nrr}/>
        )}

      </Stack>

      {/* Switch bundles button */}

      {/* <Button variant='text' sx={{ mt: '15px', p: '2px',  }} onClick={onSwitchBundlesButtonClick}>
        <ChangeCircleIcon sx={{ color: theme_limeGreen, width: '24px', height: '24px', mr: '7px' }} />
        <Typography sx={{ color: theme_limeGreen, fontSize: '1rem', textTransform: 'none' }}>
          Switch Bundle
        </Typography>
      </Button> */}

    </Stack>
  )

  // ******************************************************************************************
  // Main render - fall through case (should never happen)
  // ******************************************************************************************

  return (
    <Typography>
      Bundle unhandled state
    </Typography>
  )

  // ******************************************************************************************
  // ******************************************************************************************
  // ******************************************************************************************

}

export default Bundles;


