// Identify for a single feature

import { Paper, Stack, Table, TableBody, tableCellClasses, TableContainer, TableHead, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_bgColorMain, theme_limeGreen } from "../Theme";
import { IIdentifyFeature } from "./IdentifyInterfaces";
import { ILayer, IVectorLayerAttribute } from "../Layers/LayerInterfaces";
import IdentifyTableRow from "./IdentifyTableRow";
import { GetVectorLayerAttribute } from "../AttributeOps";
import useStore from "../store";
import { PARCEL_LAYER_NAME } from "../Globals";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface IdentifyFeatureProps
{
  layer: ILayer;
  feature: IIdentifyFeature;
}

interface IAttrib // used for sorting
{
  name: string;
  displayName: string;
}

//-------------------------------------------------------------------------------
// Identify for a single feature
//-------------------------------------------------------------------------------
const IdentifyFeature = (props: IdentifyFeatureProps) => 
{
  // Get needed state data from the store
  const { store_project, 
  } = useStore();






  //-------------------------------------------------------------------------------
  // Returns a properly sorted list of attributes keys - the sorted is done by
  // the attributes' friendly names.
  //-------------------------------------------------------------------------------
  function GetSortedAttributeKeys(): string[]
  {
    if(!store_project || !store_project.user_settings || !props.feature || !props.feature.data) return [];

    if(props.layer.name === PARCEL_LAYER_NAME && store_project.user_settings.identify_showOnlyParcelUserAttributes)
    {
      // If this is for the Parcels layer and the "showOnlyParcelUserAttributes" setting is TRUE, we
      // need to re-order they keys to match the user's order in project settings.

      const newKeys: string[] = [];
      for(let i=0; i < store_project.user_settings.parcel_attribute_list.length; i++)
        newKeys.push(store_project.user_settings.parcel_attribute_list[i].name);

      return newKeys;
    }
    else
    {
      // We need to sort by the attributes' friendly/display names

      // Get the attribute keys (a list of attribute names)

      const keys = Object.keys(props.feature.data);

      // Generate a new list that has a pairing of attribute names and their friendly display names

      let attribList: IAttrib[] = [];
      for(let i=0; i < keys.length; i++)
      {
        const att: IVectorLayerAttribute | undefined = GetVectorLayerAttribute(props.layer, keys[i]);
        if(!att) continue;

        attribList.push( { name: keys[i], displayName: att.display_name } );
      }

      // Re-sort the new list by display name
    
      attribList = attribList.sort((a, b) => a.displayName.localeCompare(b.displayName));

      // Return a list of attribute name, but sorted by display name

      const sortedKeys: string[] = [];
      for(let i=0; i < attribList.length; i++)
        sortedKeys.push(attribList[i].name);

      return sortedKeys;
    }
  }







  // Return nothing if we have no layer or feature
  if(!props.layer || !props.feature || !props.feature.data) return null;

  // Main render

  return (

    <Stack sx={{ maxHeight: '275px', overflow: 'auto' }}>

      {props.feature.singleRasterValue
        ?
          // Show only the 'singleRasterValue'

          <Typography sx={{ fontSize: '1.0rem', color: theme_limeGreen, textAlign: 'center', p: 0.8 }}>
            {props.feature.singleRasterValue}
          </Typography>

        :
        
          // Show the full list of attributes in a table

          <TableContainer component={Paper} sx={{ mt: 0.5, bgcolor: theme_bgColorMain }} elevation={0}>

            {/* <Table size="small" aria-label="simple table" sx={{ [`& .${tableCellClasses.root}`]: {borderBottom: "none"} }}> */}
            <Table size="small" aria-label="identify table" sx={{ [`& .${tableCellClasses.root}`]: {borderBottom: "1", borderColor: theme_bgColorLight1 + '8F'} }}>

              <TableHead>
              </TableHead>

              <TableBody>

                {GetSortedAttributeKeys().map(key => 
                  <IdentifyTableRow key={key} layer={props.layer} attribute_name={key} feature={props.feature} />
                )}

              </TableBody>

            </Table>
          </TableContainer>
      }
    </Stack>

  )
}


export default IdentifyFeature;
