import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import React, { useState } from 'react';
import { Grid, Stack, Link, TextField } from '@mui/material';
import UserPool from './UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import useStore from '../store';
import { stratifyx_logo } from '../Theme';
import AccountPageBase from '../Components/AccountPageBase';


//-------------------------------------------------------------------------------
// Confirm user (ie. a code was sent to the user's email address)
//-------------------------------------------------------------------------------
export default function ConfirmUser()
{

  const [errorMessage, setErrorMessage] = useState('');
  const [showLogInButton, setShowLogInButton] = useState<boolean>(false);

  const store_username = useStore(state => state.store_username);

  const user = new CognitoUser(
  {
    Username: store_username,
    Pool: UserPool,
  })

  //-------------------------------------------------------------------------------
  // Form SUBMIT
  //-------------------------------------------------------------------------------
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => 
  {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const confirmationCode: string = data.get('confirmationCode')!.toString();

    user.confirmRegistration(confirmationCode, true, function(err, result) 
    {
      if (err) 
      {
        setErrorMessage('Unable to confirm registration');
      }
      else 
      {
        setErrorMessage('');
        setShowLogInButton(true);
      }
      //Debug.log('call result: ' + result);
    });
  }

  //-------------------------------------------------------------------------------
  // Resend the user confirmation code (by email).
  //-------------------------------------------------------------------------------
  function ResendConfirmationCode()
  {
    user.resendConfirmationCode(function(err, result) 
    {
      if (err) 
        setErrorMessage('Unable to send confirmation code');
      else 
        setErrorMessage('A new confirmation code has been sent.');

      //Debug.log('call result: ' + result);
    });
  }




  // Main render - User is confirmed, show a button to go to the login page.

  if(showLogInButton)
  return (

    <AccountPageBase>

      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0 }}>
        <img src={stratifyx_logo} alt="" height='150px' />

        <Typography sx={{ fontSize: '1.3rem', color: '#1976D2', mt: 2, mb: 2 }}>
          Your email has been confirmed.
        </Typography>

        <Button variant="contained" sx={{ mt: 3, mb: 4 }} component={RouterLink} to="/Account/LogIn" >
          Proceed to Log In
        </Button>

      </Box>

    </AccountPageBase>
  )



  // Main render - main confirmation page (ask user for code)

  return (

    <AccountPageBase>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0 }}>

        <img src={stratifyx_logo} alt="" height='150px' />

        <Typography sx={{ fontSize: '1.3rem', color: '#1976D2', mt: 1 }}>
          A confirmation code has been sent by text or email.
        </Typography>

        <Grid sx={{ marginTop: 2}}>

          <Grid item xs={12}>
            <TextField margin="normal" required fullWidth name="confirmationCode" 
                       label="Confirmation Code" id="confirmationCode"/>
          </Grid>

          <Grid item xs={12}>
            <Stack direction='row'>

              <Button type="submit" variant="contained" sx={{ mt: 1, mb: 1 }} >
                Confirm Code
              </Button>

              <Button variant="outlined" sx={{ ml: 1, mt: 1, mb: 1 }} 
                    onClick={() => { ResendConfirmationCode(); }}>
                Resend
              </Button>
            </Stack>
          </Grid>

        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }} >
          <Typography color='error.main' alignContent='center'>
            {errorMessage}
          </Typography>
        </Grid>

        <Link href="/" variant="body2" sx={{ mt: 4 }}>Back to App</Link>

      </Box>

    </AccountPageBase>
  )
}