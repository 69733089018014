// Vector attribute operations

import { FriendlyNumber, IsURL } from "./Globals";
import { ILayer, IVectorLayerAttribute } from "./Layers/LayerInterfaces";
import { Link } from "@mui/material";
import { GetLayerByID } from "./Layers/LayerOps";
import { GetParcelLayer } from "./Parcels/ParcelOps";





//-------------------------------------------------------------------------------
// Get the attribute info for the specified layer/attribute.
//-------------------------------------------------------------------------------
export function GetVectorLayerAttribute(layer: ILayer, attribute_name: string): IVectorLayerAttribute | undefined
{
  if(!layer || !layer.attributes || !attribute_name) return undefined;
  return layer.attributes.find(attrib => attrib.attribute_name === attribute_name);
}

//-------------------------------------------------------------------------------
// Get the attribute info for the specified layer/attribute.
//-------------------------------------------------------------------------------
export function GetVectorLayerAttribute2(layer_id: number, attribute_name: string): IVectorLayerAttribute | undefined
{
  if(!layer_id || !attribute_name) return undefined;

  const layer: ILayer | null = GetLayerByID(layer_id);
  if(!layer || !layer.attributes) return undefined;

  return GetVectorLayerAttribute(layer, attribute_name);
}

//-------------------------------------------------------------------------------
// Get the parcel layer attribute info for the specified attribute.
//-------------------------------------------------------------------------------
export function GetParcelLayerAttribute(attribute_name: string | undefined): IVectorLayerAttribute | undefined
{
  if(!attribute_name) return undefined;

  const layer: ILayer | undefined = GetParcelLayer();
  if(!layer || !layer.attributes) return undefined;

  return GetVectorLayerAttribute(layer, attribute_name);
}

//-------------------------------------------------------------------------------
// Render the specified attribute value.
//-------------------------------------------------------------------------------
export function RenderVectorLayerAttributeValue(attribute: IVectorLayerAttribute, attribute_value: any): any
{
  const valueStr: string = attribute_value;

  // Numerical value

  if(attribute.data_type === 'number') // Number
  {
    const valueNum: number = Number.parseFloat(valueStr);
    return (
      FriendlyNumber(valueNum, attribute.decimal_places !== undefined ? attribute.decimal_places : 0)
    )
  }

  // String or Enum value

  else
  return (
    IsURL(valueStr)
      ?
        <Link href={valueStr} target="_blank">
          {valueStr}
        </Link>
      :
        valueStr
  )
}
