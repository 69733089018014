// AOI operations

import { GeoJSON, MultiPolygon, Feature } from "geojson";
import { CallServer } from "../CallServer";
import Debug from "../Debug";
import useStore from "../store";
import { IAoi, IAoiListItem, TAoiExportFormat } from "./AoiInterfaces";
import { IProjectAoi } from "../Projects/ProjectInterfaces";
import { AddDrawControlToMap, ZoomMapToGeojsonExtent, RemoveDrawControlFromMap } from "../Map/MapOps";
import { ToastNotification } from "../ToastNotifications";
import { AnyLayer } from "mapbox-gl";
import { AOI_BBOX_EXTENT_MAX_SIZE_ACRES, AOI_MAX_SIZE_ACRES, FriendlyNumber } from "../Globals";
import { ResetHbv } from "../HBV/HbvOps";
import { ConvertFeatureCollectionToMultiPolygon, ConvertMultiPolygonToFeatureCollection, ConvertPolygonToFeatureCollection, DetectIntersection, DetectSelfIntersection, GetGeojsonAcres, GetGeojsonBBoxAcres, GetPolygonCount } from "../GisOps";
import { ExitParcelsMode } from "../Parcels/ParcelOps";


const ACTIVE_AOI_LAYER_MAPBOX_SOURCE_NAME: string = 'aoi-layer-source';
export const ACTIVE_AOI_LAYER_MAPBOX_LAYER_1_NAME: string = 'aoi-layer-1';
const ACTIVE_AOI_LAYER_MAPBOX_LAYER_2_NAME: string = 'aoi-layer-2';



//-------------------------------------------------------------------------------
// Load the specified AOI.
//-------------------------------------------------------------------------------
export async function LoadAoi(aoi_id: number, flagProjectAsDirty: boolean = true): Promise<boolean>
{
  ResetHbv();

  // Call the server to get the data

  const server = new CallServer();
  server.Add("aoi_id", aoi_id);
  server.Add("srid", 4326); // EPSG:4326 = WSG84 = lat/long coords
  //server.Add("srid", 3857); // 3857 (web mercator)

  useStore.getState().store_setAoiIsLoading(true);  // Tell the UI we are loading the AOI

  const result = await server.Call('get', '/aoi');

  useStore.getState().store_setAoiIsLoading(false);  // Tell the UI we are no longer loading the AOI

  if(result.success)
  {
    Debug.log('AoiOps.LoadAoi> API server call SUCCESS');
    //Debug.log('AoiOps.LoadAoi> SUCCESS! data=' + JSON.stringify(result.data));
    
    const loadedAoi: IAoi | null = result.data;

    if(!loadedAoi || !result.data.geom)
    {
      Debug.error('AoiOps.LoadAoi> Received NULL data or geom');
      return false;
    }

    loadedAoi['polygonCount'] = GetAoiPolygonCount(result.data.geom);
    loadedAoi['acres'] = result.data.geom.type === undefined ? 0 : GetGeojsonAcres(result.data.geom);
    loadedAoi['lastSavedGeom'] = result.data.geom;

    // Update the state store
    useStore.getState().store_setAoi(loadedAoi);
    useStore.getState().store_setProjectLastActiveAoi(aoi_id);

    if(flagProjectAsDirty)
      useStore.getState().store_setProjectIsDirty(true);

    // Load it into the map draw tool (it can handle empty aois)
    //LoadAoiIntoMapDrawTool(result.data.geom);

    AddAoiLayerToMap();

    if(loadedAoi.geom)
      ZoomMapToGeojsonExtent(loadedAoi.geom);

    // Check the AOI for errors (and warn the user if any errors are detected)
    const errorMessage: string | null = DetectAoiErrors(loadedAoi);
    if(errorMessage)
    {
      ToastNotification('warning',  errorMessage);
      return false;
    }

    // Success
    Debug.log(`Aoi.LoadAoi> AOI id ${aoi_id} loaded.`);
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load AOI")
    Debug.error('AoiOps.LoadAoi> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Create a new AOI.
//
// NOTE: This is called when a new blank AOI is created (no geometry), as well as
//       from the "create aoi from parcels" modes, where a geometry is specified.
//-------------------------------------------------------------------------------
export async function CreateNewAoi(name: string, geometry: GeoJSON | undefined, enterAOIEditMode: boolean): Promise<boolean>
{
  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id)
  {
    Debug.warn(`AoiOps.CreateNewAoi> null project or project id`);
    return false;
  }

  if(!store_project.aoi_group_id) return false;
  
  ResetHbv();

  // Remove the old AOI from the map
  RemoveAoiLayerFromMap();
  
  // Call server to create the new AOI

  const server = new CallServer();
  server.Add('aoi_group_id', store_project.aoi_group_id); // the new AOI will be added to this AOI group
  server.Add('aoi_name', name);
  if(geometry)
  {
    server.Add('geojson', JSON.stringify(geometry));
    server.Add("srid", 4326); // EPSG:4326 = WSG84 = lat/long coords
  }
  
  useStore.getState().store_setAoiIsSaving(true);

  const result = await server.Call('post', '/aoi');

  useStore.getState().store_setAoiIsSaving(false);

  if(result.success)
  {
    Debug.log('AoiOps.CreateNewAoi> API server call SUCCESS');
    //Debug.log('AoiOps.CreateNewAoi> SUCCESS! data=' + JSON.stringify(result.data));

    const new_aoi_id = result.data.aoi_id;

    if(!new_aoi_id || new_aoi_id <= 0)
    {
      Debug.error('AoiOps.CreateNewAoi> Received null or invalid id');
      return false;
    }

    // For shared project sync, any time we make changes to AOIS or scenarios we get an
    // updated server-side date, and we use that as the new "project load date" so that 
    // a sync is not triggered based on the user's own changes.
    if(result.data && result.data.date_time)
      useStore.getState().store_setProjectServerSideLoadDate(result.data.date_time);

    // Add the new Aoi to the active project's aoi list (just in the state store - it's already done in the db)

    const newAoiListItem: IProjectAoi = 
    {
      aoi_id: new_aoi_id,
      aoi_name: name,
    }

    useStore.getState().store_projectAddAoi(newAoiListItem);

    // Make this new AOI the "active" AOI

    const newAoi: IAoi = 
    {
      aoi_id: new_aoi_id,
      aoi_name: name,
      geom: null,
      isDirty: false,
      updatedGeom: null,
      lastSavedGeom: null,
      polygonCount: 0,
      acres: 0,
    }

    if(geometry)
    {
      newAoi.geom = geometry;
      newAoi.acres = GetGeojsonAcres(geometry);
      newAoi.polygonCount = GetPolygonCount(geometry);
    }

    useStore.getState().store_setAoi(newAoi);
    useStore.getState().store_setProjectLastActiveAoi(new_aoi_id);
    useStore.getState().store_setProjectIsDirty(true);
    useStore.getState().store_setAoiUIMode('default');


    // Clear out the draw tool (ie clear any polygons from the previously-active AOI)
    const mapDrawControl: MapboxDraw | null = useStore.getState().store_mapDrawControl;
    mapDrawControl?.deleteAll();

    // Enter AOI edit mode
    if(enterAOIEditMode)
      EnterAoiEditMode();
    else
      AddAoiLayerToMap();

    // if(addAoiLayerToMap)
    //   AddAoiLayerToMap();

    // Success
    Debug.log(`Aoi.CreateNewAoi> AOI id ${new_aoi_id} created.`);
    return true;
  }
  else
  {
    // Failure

    //'{"detail":"AOI name already exists in project"}'
    //'{"detail":"AOI name already exists for user"}'
    if(result.errorMessage.toLowerCase().includes('already exists'))
      ToastNotification('error', "An AOI with that name already exists")
    else
      ToastNotification('error', "Unable to create a new AOI")

    Debug.error(`AoiOps.CreateNewAoi> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Save the active AOI.
//-------------------------------------------------------------------------------
export async function SaveActiveAoi(): Promise<boolean>
{
  // NOTE:  If there is updated geom data in 'store_aoi.updatedGeom', that is what
  //        gets saved, otherwise we default back to using 'store_aoi.geom' (so the
  //        geom does not change).
  //
  //        This method is used for saving changes to AOI 'properties' as well (ex AOI Notes).

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id)
  {
    Debug.warn(`AoiOps.SaveActiveAoi> null project or project id`);
    return false;
  }

  const store_aoi = useStore.getState().store_aoi;
  if(!store_aoi)
  {
    Debug.warn(`AoiOps.SaveActiveAoi> null aoi`);
    return false;
  }

  let multiPolyGeojson: GeoJSON | null = null;
  let drawData = undefined;

  if(store_aoi.updatedGeom)
  {
    // If there is data in 'updatedGeom' that means the user has altered the geometry
    // in the app.

    // const store_mapDrawControl = useStore.getState().store_mapDrawControl;
    // if(!store_aoi.updatedGeom && !store_mapDrawControl) 
    // {
    //   // 'updatedGeom' is empty
    //   Debug.warn(`AoiOps.SaveActiveAoi> null map draw control`);
    //   return false;
    // }

    // NOTE: Any time the user makes changes to the active AOI's polygons, the changes get saved 
    //       to store_aoi.updatedGeom.  What's in there should be an exact match to 'getAll()'.
    //const drawData = store_aoi.updatedGeom ? store_aoi.updatedGeom : store_mapDrawControl.getAll();
    drawData = store_aoi.updatedGeom;

    // Convert the geojson data from 'getAll()' to PostGIS MultiPolygon format
    // that is required by the API.

    multiPolyGeojson = ConvertFeatureCollectionToMultiPolygon(drawData);
  }
  else // No data in 'updatedGeom' means the geometry has not changed (we use the data from 'geom')
    multiPolyGeojson = store_aoi.geom;

  // const multiPolyGeojson: GeoJSON = 
  // {
  //   type: "MultiPolygon",
  //   coordinates: []
  // }

  // for(let f=0; f < drawData.features.length; f++)
  // {
  //   const geometry : Geometry = drawData.features[f].geometry;
  //   if (geometry.type === 'Polygon')
  //     multiPolyGeojson.coordinates.push(geometry.coordinates);
  //   else if (geometry.type === 'MultiPolygon')
  //   {
  //     for(let p=0; p < geometry.coordinates.length; p++)
  //       multiPolyGeojson.coordinates.push(geometry.coordinates[p]);
  //   }
  //   else
  //     Debug.warn(`AoiOps.SaveActiveAoi> Invalid geometry type (${geometry.type})`);
  // }
 
  // Call server to save the AOI

  const server = new CallServer();
  server.Add('aoi_id', store_aoi.aoi_id);
  if(!multiPolyGeojson || Object.keys(multiPolyGeojson).length)
    server.Add('geojson', JSON.stringify(multiPolyGeojson));
  server.Add("srid", 4326); // EPSG:4326 = WSG84 = lat/long coords
  if(store_aoi.properties)
    server.Add("properties", store_aoi.properties);
  
  useStore.getState().store_setAoiIsSaving(true);

  const result = await server.Call('put', '/aoi');

  useStore.getState().store_setAoiIsSaving(false);

  if(result.success)
  {
    Debug.log('AoiOps.SaveActiveAoi> API server call SUCCESS');
    //Debug.log('AoiOps.SaveActiveAoi> SUCCESS! data=' + JSON.stringify(result.data));

    // For shared project sync, any time we make changes to AOIS or scenarios we get an
    // updated server-side date, and we use that as the new "project load date" so that 
    // a sync is not triggered based on the user's own changes.
    if(result.data && result.data.date_time)
      useStore.getState().store_setProjectServerSideLoadDate(result.data.date_time);

    // Remember this aoi in case the user uses the "Revert" button
    useStore.getState().store_setAoiLastSavedGeom(drawData);

    useStore.getState().store_setAoiIsDirty(false);

    // Success
    Debug.log(`Aoi.SaveActiveAoi> AOI id ${store_aoi.aoi_id} saved.`);
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to save AOI")
    Debug.error(`AoiOps.SaveActiveAoi> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Rename the active AOI.
//-------------------------------------------------------------------------------
export async function RenameActiveAoi(newAoiName: string): Promise<boolean>
{
  const store_aoi = useStore.getState().store_aoi;
  if(!store_aoi)
  {
    Debug.warn(`AoiOps.RenameActiveAoi> null aoi`);
    return false;
  }

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id)
  {
    Debug.warn(`AoiOps.RenameActiveAoi> null project or project_id`);
    return false;
  }

  // Call server to save the AOI

  const server = new CallServer();
  server.Add('aoi_id', store_aoi.aoi_id);
  server.Add('aoi_name', newAoiName);
  server.Add('project_id', store_project.project_id);
  
  useStore.getState().store_setAoiIsSaving(true);

  const result = await server.Call('put', '/aoi');

  useStore.getState().store_setAoiIsSaving(false);

  if(result.success)
  {
    Debug.log('AoiOps.RenameActiveAoi> API server call SUCCESS');
    //Debug.log('AoiOps.RenameActiveAoi> SUCCESS! data=' + JSON.stringify(result.data));

    // For shared project sync, any time we make changes to AOIS or scenarios we get an
    // updated server-side date, and we use that as the new "project load date" so that 
    // a sync is not triggered based on the user's own changes.
    if(result.data && result.data.date_time)
      useStore.getState().store_setProjectServerSideLoadDate(result.data.date_time);

    // Update the state store
    useStore.getState().store_setAoiName(newAoiName);
    useStore.getState().store_projectRenameAoi(store_aoi.aoi_id, newAoiName);
    useStore.getState().store_setAoiUIMode('default');

    // Success
    Debug.log(`Aoi.RenameActiveAoi> AOI id ${store_aoi.aoi_id} has been renamed.`);
    return true;
  }
  else
  {
    // Failure

    if(result.errorMessage.toLowerCase().includes('already exists'))
      ToastNotification('error', "An AOI with that name already exists")
    else
      ToastNotification('error', "Unable to rename AOI")

    Debug.error(`AoiOps.RenameActiveAoi> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}
/*
//-------------------------------------------------------------------------------
// Remove the active AOI from the project (without deleting it).
//-------------------------------------------------------------------------------
export async function RemoveActiveAoi()
{
  let store_project = useStore.getState().store_project;
  if(!store_project) return;

  const store_aoi = useStore.getState().store_aoi;
  if(!store_aoi) return;

  ResetHbv();

  // Clear out the draw tool

  const mapDrawControl: MapboxDraw | null = useStore.getState().store_mapDrawControl;
  mapDrawControl?.deleteAll();

  // NOTE: We just remove the AOI from the active project in the state store and
  //       flag the project as "dirty", which will trigger project auto-save.
  
  useStore.getState().store_projectRemoveAoi(store_aoi.aoi_id);

  RemoveAoiLayerFromMap();

  // Now that the "active" AOI is gone, we must either select a new AOI (if one is 
  // available), or switch the UI into "create new AOI" mode.

  store_project = useStore.getState().store_project;  // need to refresh it after removing the aoi above

  if(store_project && store_project.aois.length >= 1)
    await LoadAoi(store_project.aois[0].aoi_id);  // There are other AOIs in this project - auto-switch to the first one
  else
  {
    useStore.getState().store_setAoi(null);
    useStore.getState().store_setProjectLastActiveAoi(null);
    useStore.getState().store_setAoiUIMode('create'); // There are no other AOIs in the project - switch the UI to "Create new AOI" mode
  }

  // We could just let the auto-timer save, but doing it right away here
  SaveActiveProject();
}
*/
//-------------------------------------------------------------------------------
// Delete the active AOI.
// 
// If this AOI only exists in the active project, then it is permanently deleted.
// If the AOI exists as part of any other project, then it is only removed from
// the active project.
//-------------------------------------------------------------------------------
export async function DeleteActiveAoi(): Promise<boolean>
{
  const store_aoi = useStore.getState().store_aoi;
  if(!store_aoi)
  {
    Debug.warn(`AoiOps.DeleteActiveAoi> null aoi`);
    return false;
  }

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id)
  {
    Debug.warn(`AoiOps.DeleteActiveAoi> null project or project_id`);
    return false;
  }

  if(!store_project.aoi_group_id) return false;

  ResetHbv();

  // Call server to delete the AOI

  const server = new CallServer();
  server.Add('aoi_id', store_aoi.aoi_id);
  server.Add('aoi_group_id', store_project.aoi_group_id);
  
  useStore.getState().store_setAoiIsDeleting(true);

  const result = await server.Call('delete', '/aoi');

  useStore.getState().store_setAoiIsDeleting(false);

  if(result.success)
  {
    Debug.log('AoiOps.DeleteActiveAoi> API server call SUCCESS');
    //Debug.log('AoiOps.DeleteActiveAoi> SUCCESS! data=' + JSON.stringify(result.data));

    // For shared project sync, any time we make changes to AOIS or scenarios we get an
    // updated server-side date, and we use that as the new "project load date" so that 
    // a sync is not triggered based on the user's own changes.
    if(result.data && result.data.date_time)
      useStore.getState().store_setProjectServerSideLoadDate(result.data.date_time);

    // Update the state store
    useStore.getState().store_projectRemoveAoi(store_aoi.aoi_id);

    const store_project = useStore.getState().store_project;
    if(!store_project)
    {
      Debug.warn(`AoiOps.DeleteActiveAoi> null project`);
      return false;
    }

    // Clear out the draw tool

    const mapDrawControl: MapboxDraw | null = useStore.getState().store_mapDrawControl;
    mapDrawControl?.deleteAll();

    RemoveAoiLayerFromMap();

    // Now that the "active" AOI is gone, we must either select a new AOI (if one is 
    // available), or switch the UI into "create new AOI" mode.
    
    if(store_project && store_project.aois.length >= 1)
      await LoadAoi(store_project.aois[0].aoi_id);  // There are other AOIs in this project - auto-switch to the first one
    else
    {
      useStore.getState().store_setAoi(null);
      useStore.getState().store_setProjectLastActiveAoi(null);
      useStore.getState().store_setProjectIsDirty(true);
      useStore.getState().store_setAoiUIMode('default');
    }

    // Success
    Debug.log(`Aoi.DeleteActiveAoi> AOI id ${store_aoi.aoi_id} has been deleted.`);
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', `Unable to delete AOI '${store_aoi.aoi_name}'`)
    Debug.error(`AoiOps.DeleteActiveAoi> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Load the specified AOI into the map draw control.
//-------------------------------------------------------------------------------
export function LoadAoiIntoMapDrawTool(geom: any, autoZoomToExtent: boolean = true)
{
  // Remove any previous shapes in the draw tool

  const mapDrawControl: MapboxDraw | null = useStore.getState().store_mapDrawControl;
  if(!mapDrawControl) return;
  mapDrawControl.deleteAll();

  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  if(!geom) return;

  // The AOI is a single polygon

  if(geom.type === undefined) // this will be the case for AOIs with no geometry saved yet (not an error)
    return;
  else if(geom.type === 'Polygon')
  {
    const feature : Feature = 
    {
      type: "Feature",
      properties: {},
      geometry: 
      {
        type: 'Polygon',
        coordinates: geom.coordinates
      }
    }

    useStore.getState().store_setAoiPolygonCount(1);

    mapDrawControl.add(feature);

    if(autoZoomToExtent)
      ZoomMapToGeojsonExtent(geom);
  }
  else if(geom.type === 'MultiPolygon')
  {
    // The AOI is a MultiPolygon

    const aoi_data: MultiPolygon = geom;

    if(aoi_data.coordinates.length > 0)
    {
      // We could add the aoi_data as a single MultiPolygon, but if we do all polys
      // will be combined into one (moving one, moves them all etc).
      //
      // So instead we will separate them all out into separate Features, where
      // each feature will get a separate ID and contain a single separate polygon.
      
      for(let i=0; i < aoi_data.coordinates.length; i++)
      {
        const feature: Feature = 
        {
          type: "Feature",
          properties: {},
          geometry: 
          {
            type: 'Polygon',
            coordinates: aoi_data.coordinates[i]
          }
        }

        mapDrawControl.add(feature);

        useStore.getState().store_setAoiPolygonCount(aoi_data.coordinates.length);
      }

      if(autoZoomToExtent)
        ZoomMapToGeojsonExtent(geom);
    }
    else
    {
      useStore.getState().store_setAoiPolygonCount(0);
      useStore.getState().store_setAoiAcres(0);
    }
  }
  else if(geom.type === 'FeatureCollection')
  {
    // This mode is used when the user has modified the polygons with the draw tool, and the
    // data was temp saved to store_aoi.updatedGeom.

    if(geom.features.length >= 1)
    {
      for(let i=0; i < geom.features.length; i++)
        mapDrawControl.add(geom.features[i]);

      if(autoZoomToExtent)
        ZoomMapToGeojsonExtent(geom);
    }

    useStore.getState().store_setAoiPolygonCount(geom.features.length);
  }
  else
  {
    // Unsupported AOI geometry type
    Debug.warn(`AoiOps.LoadAoiIntoMapDrawTool> Unsupported AOI geometry type (${geom.type})`)
  }
}

//-------------------------------------------------------------------------------
// Get the number of polygons in the specified AOI geojson. 
//-------------------------------------------------------------------------------
export function GetAoiPolygonCount(geom: any): number
{
  if(!geom) return 0;

  // The AOI is a single polygon

  if(geom.type === undefined) // this will be the case for AOIs with no geometry saved yet (not an error)
    return 0;
  else if(geom.type === 'Polygon')
    return 1;
  else if(geom.type === 'MultiPolygon')
    return geom.coordinates.length;
  else if(geom.type === 'FeatureCollection')
    return geom.features.length;

  return 0;
}

//-------------------------------------------------------------------------------
// Load the list of AOIs for this user.
//-------------------------------------------------------------------------------
export async function LoadAoiList(): Promise<boolean>
{
  // Call the server to get the data

  const server = new CallServer();

  useStore.getState().store_setAoiListIsLoading(true);  // Tell the UI we are loading the AOI list

  const result = await server.Call('get', '/aois');

  useStore.getState().store_setAoiListIsLoading(false);  // Tell the UI we are now longer loading the AOI list

  if(result.success)
  {
    Debug.log('AoiOps.LoadAoiList> API server call SUCCESS');
    //Debug.log('AoiOps.LoadAoiList> SUCCESS! data=' + JSON.stringify(result.data));
    
    const aoiList: IAoiListItem[] = result.data;

    // We don't want to include all AOIs in this list - we need to filter out
    // all AOIs already part of the active project.

    const store_project = useStore.getState().store_project;
    if(store_project && store_project.aois)
    {
      let finalAoiList: IAoiListItem[] = [];
      
      for(let i=0; i < aoiList.length; i++)
      {
        // Check if this AOI is part of the active project's AOI list

        let found: boolean =false;
        for(let j=0; j < store_project.aois.length; j++)
          if(store_project.aois[j].aoi_id === aoiList[i].aoi_id)
          {
            found = true;
            break;
          }

        if(!found)  // Not found - copy the item into the finalAoiList
          finalAoiList.push(aoiList[i]);
      }

      // Sort the AOI list by name (asc)
      finalAoiList = finalAoiList.sort((a: IAoiListItem, b: IAoiListItem) => a.aoi_name.localeCompare(b.aoi_name));

      // Update the state store
      useStore.getState().store_setAoiList(finalAoiList);
    }
    else  // Something went wrong (no project!) - include all AOIs in the list
    {
      // Update the state store
      useStore.getState().store_setAoiList(aoiList);
    }

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load AOI list")
    Debug.error('AoiOps.LoadAoiList> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// The draw tool will notify here if the user makes any changes to the active AOI.
// - add new polygons
// - delete polygons
// - edit polygons
//-------------------------------------------------------------------------------
export function onMapboxDrawChange(e: any)
{
  // Get the latest data from the map draw control

  const store_mapDrawControl = useStore.getState().store_mapDrawControl;
  if(!store_mapDrawControl) return;

  const newData = store_mapDrawControl.getAll();

  // Update the state store

  useStore.getState().store_setAoiUpdatedGeom(newData);
  useStore.getState().store_setAoiPolygonCount(newData.features.length);
  useStore.getState().store_setAoiIsDirty(true);

  const aoiTotalAcres: number = GetGeojsonAcres(newData);
  useStore.getState().store_setAoiAcres(aoiTotalAcres)

  // Check the AOI for errors (and warn the user if any errors are detected)

  const errorMessage: string | null = DetectAoiErrors(useStore.getState().store_aoi);
  if(errorMessage)
  {
    ToastNotification('warning',  errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Add the special AOI layer to the map.
//-------------------------------------------------------------------------------
export function AddAoiLayerToMap()
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // Remove any previous AOI layer from the map (if there is one)
  RemoveAoiLayerFromMap();

  // Don't add if we are currently in AOI edit mode
  //if(useStore.getState().store_aoiUIMode === 'edit') return;

  const store_aoi = useStore.getState().store_aoi;
  if(!store_aoi) return;

  // If 'updatedGeom' is set, use that (it means the user has recently edited this aoi).
  // Otherwise check if 'geom' is set - that will be the AOI as loaded from the API.
  let geom: any = null
  if(store_aoi.updatedGeom)
    geom = store_aoi.updatedGeom;
  else if(store_aoi.geom && store_aoi.geom.type !== undefined)
    geom = store_aoi.geom;
  else
    return; // no aoi geometry available

  store_map.addSource(ACTIVE_AOI_LAYER_MAPBOX_SOURCE_NAME,
  {
    type: 'geojson',
    data: geom,
  })

  // Add mapbox layer 1 (a darker and larger blurry background polygon)

  const newMapboxLayer1: mapboxgl.Layer = 
  {
    'id': ACTIVE_AOI_LAYER_MAPBOX_LAYER_1_NAME,
    'source': ACTIVE_AOI_LAYER_MAPBOX_SOURCE_NAME,
    'type': 'line',
    'paint': 
    {
      'line-color': '#000000',
      'line-width': 10,
      'line-blur': 2,
      //'line-gap-width': 2,
      'line-opacity': 1,
    }
  };

  store_map.addLayer(newMapboxLayer1 as AnyLayer);

  // Add mapbox layer 2 (the thinner cyan main polygon)

  const newMapboxLayer2: mapboxgl.Layer = 
  {
    'id': ACTIVE_AOI_LAYER_MAPBOX_LAYER_2_NAME,
    'source': ACTIVE_AOI_LAYER_MAPBOX_SOURCE_NAME,
    'type': 'line',
    'paint': 
    {
      'line-color': '#3BB2D0',//'rgba(1,0,0,1)',
      'line-width': 3,
      //'line-blur': 0,
      //'line-gap-width': 2,
      'line-opacity': 1,
    }
  };

  store_map.addLayer(newMapboxLayer2 as AnyLayer);
}

//-------------------------------------------------------------------------------
// Remove the special AOI layer from the map.
//-------------------------------------------------------------------------------
export function RemoveAoiLayerFromMap()
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // Remove the layers

  if(store_map.getLayer(ACTIVE_AOI_LAYER_MAPBOX_LAYER_1_NAME))
    store_map.removeLayer(ACTIVE_AOI_LAYER_MAPBOX_LAYER_1_NAME);

  if(store_map.getLayer(ACTIVE_AOI_LAYER_MAPBOX_LAYER_2_NAME))
    store_map.removeLayer(ACTIVE_AOI_LAYER_MAPBOX_LAYER_2_NAME);

  // Remove the source
  if(store_map.getSource(ACTIVE_AOI_LAYER_MAPBOX_SOURCE_NAME))
    store_map.removeSource(ACTIVE_AOI_LAYER_MAPBOX_SOURCE_NAME);
}

//-------------------------------------------------------------------------------
// Checks the specified AOI for various issues.
//
// * the total polygon are must not exceed 30,000 acres
// * the bounding box extent ares must not exceed 60,000 acres
// * none of the AOI's polygons must self-intersect
// * no 2 polygons within the AOI can intersect with each other
// 
// Returns an error message, or NULL if the AOI is good.
//-------------------------------------------------------------------------------
export function DetectAoiErrors(aoi: IAoi | null): string | null
{
  if(!aoi) return null;

  // Check if the AOI has no polygons

  if(aoi.polygonCount === undefined || aoi.polygonCount === 0)
    return 'The active AOI is empty (no areas defined)';

  // Check the AOI's total area

  if(aoi.acres > AOI_MAX_SIZE_ACRES)
   return `The defined area (${FriendlyNumber(aoi.acres)} acres) is too large.  It must not exceed ${FriendlyNumber(AOI_MAX_SIZE_ACRES)} acres.`;

  // Get the Geojson FeatureCollection for the AOI

  const aoiGeojson: GeoJSON | undefined = GetGeojsonFeatureCollectionForAoi(aoi);
  if(!aoiGeojson)
    return null;

  // Check the AOI's bounding box extent area

  const aoiBBoxExtentAcres: number = GetGeojsonBBoxAcres(aoiGeojson);
  if(aoiBBoxExtentAcres > AOI_BBOX_EXTENT_MAX_SIZE_ACRES)
    return `The AOI's bounding box area of ${FriendlyNumber(aoiBBoxExtentAcres)} acres exceeds the maximum ${FriendlyNumber(AOI_BBOX_EXTENT_MAX_SIZE_ACRES)} acres.  The polygons are too far apart.`;

  // Check if any of the polygons self-intersect

  if(DetectSelfIntersection(aoiGeojson))
    return 'This AOI contains a polygon that self-intersects.';

  // Check if any 2 polygons intesect each other
    
  if(DetectIntersection(aoiGeojson))
    return 'This AOI contains polygons that intersect.';

  return null; // no issues found
}

//-------------------------------------------------------------------------------
// Returns a normalized Geojson FeatureCollection with one polygon per Feature
// for the specified AOI.  Works with both recently-saved data in 'aoi.updatedGeom'
// (which is already in the required format) and data from the API in 'aoi.geom' 
// (which is normally stored as MultiPolygon format but can also be Polygon format).
//-------------------------------------------------------------------------------
export function GetGeojsonFeatureCollectionForAoi(aoi: IAoi | null): GeoJSON | undefined
{
  if(!aoi) return undefined;
  
  // If 'updateGeom' is set, that means the user has recently updated the AOI, and 
  // the draw tool returns data as a Geojson FeatureCollection already (with one
  // polygon per feature), so we can just return it as-is.

  if(aoi.updatedGeom)
    return aoi.updatedGeom;

  // If we're here, we need to use the 'geom' data, which comes directly from
  // the last API call and can be in Geojson MultiPolygon or Polygon format.
  // We need to convert it into a FeatureCollection where each feature contains
  // one polygon.

  if(aoi.geom)
  {
    if(aoi.geom.type === 'MultiPolygon') return ConvertMultiPolygonToFeatureCollection(aoi.geom);
    else if(aoi.geom.type === 'Polygon') return ConvertPolygonToFeatureCollection(aoi.geom);
    else
      Debug.error('AoiOps.GetGeojsonFeatureCollectionForAoi> aoi.geom is not in Polygon or MultiPolygon format')
  }
 
  Debug.error('AoiOps.GetGeojsonFeatureCollectionForAoi> aoi.geom is null or undefined')
  return undefined;  
}

//-------------------------------------------------------------------------------
// Upload an AOI from a local file.
//-------------------------------------------------------------------------------
export async function UploadAoiFromFile(file: File, newAoiName: string): Promise<boolean>
{
  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id || !store_project.aoi_group_id)
  {
    Debug.error('AoiOps.UploadAoiFromFile> Invalid project');
    return false;
  }

  // Call the server to get the data

  const server = new CallServer();
  server.AddFile(file);
  server.Add('aoi_group_id', store_project.aoi_group_id);
  server.Add('aoi_name', newAoiName);

  useStore.getState().store_setAoiFileIsUploading(true);  // Tell the UI we are loading data

  const result = await server.UploadFile('/import_aoi_file');

  useStore.getState().store_setAoiFileIsUploading(false);  // Tell the UI we are now longer loading data

  if(result.success)
  {
    Debug.log('AoiOps.UploadAoiFromFile> API server call SUCCESS');
    //Debug.log('AoiOps.UploadAoiFromFile> SUCCESS! data=' + JSON.stringify(result.data));

    const new_aoi_id = result.data.aoi_id;
    if(!new_aoi_id)
    {
      ToastNotification('error', "Unable to upload AOI file")
      Debug.error('AoiOps.UploadAoiFromFile> Received invalid new AOI ID');
      return false;
    }

    // Add the new AOI to the aoi list

    const newAoiItem: IProjectAoi = 
    {
      aoi_id: new_aoi_id,
      aoi_name: newAoiName,
    }

    useStore.getState().store_projectAddAoi(newAoiItem);

    ToastNotification('success', "The AOI import was successfull");

    // Load in the new AOI
    await LoadAoi(new_aoi_id);

    // Detect AOI errors and report the first one to the user
    const aoiErrorMessage = DetectAoiErrors(useStore.getState().store_aoi);
    if(aoiErrorMessage)
      ToastNotification('error', aoiErrorMessage);

    // Success
    return true;
  }
  else
  {
    // Failure

    if(result.errorMessage.toLowerCase().includes('already exists'))
      ToastNotification('error', "An AOI with that name already exists")
    else
      ToastNotification('error', "Unable to upload AOI file")

    Debug.error('AoiOps.UploadAoiFromFile> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// When editing an AOI, revert to the previously-saved (or loaded) AOI.
//-------------------------------------------------------------------------------
export function EditModeRevertAoi()
{
  const store_aoi = useStore.getState().store_aoi;
  if(!store_aoi) return;

  if(!store_aoi.lastSavedGeom || store_aoi.lastSavedGeom.type === undefined)
    return;

  // Revert the AOI changes

  LoadAoiIntoMapDrawTool(store_aoi.lastSavedGeom, false);

  useStore.getState().store_setAoiUpdatedGeom(store_aoi.lastSavedGeom);
  useStore.getState().store_setAoiIsDirty(false);

  // Need to also refresh the polygon count and the acres
  useStore.getState().store_setAoiPolygonCount(GetAoiPolygonCount(store_aoi.lastSavedGeom));
  useStore.getState().store_setAoiAcres(GetGeojsonAcres(store_aoi.lastSavedGeom));
}

//-------------------------------------------------------------------------------
// Enable AOI edit mode.
//-------------------------------------------------------------------------------
export function EnterAoiEditMode(zoomToAOI: boolean = false)
{
  // AOI edit mode and parcel mode cannot be enabled at the same time (they each have clickable elements)
  ExitParcelsMode();

  useStore.getState().store_setAoiUIMode('edit');

  // Turn off the AOI boundary visual layer (the edit mode provides it's own visuals)
  RemoveAoiLayerFromMap();

  // Zoom the map to the AOI location (optional)
  if(zoomToAOI)
    ZoomToActiveAOI();

  // Enable the polygon draw controls
  AddDrawControlToMap();

  // Load the AOI into the draw tool

  const store_aoi = useStore.getState().store_aoi;
  if(store_aoi)
  {
    if(store_aoi.updatedGeom)
      LoadAoiIntoMapDrawTool(store_aoi.updatedGeom, false);
    else
      LoadAoiIntoMapDrawTool(store_aoi.geom, false);
  }
}

//-------------------------------------------------------------------------------
// Disable AOI edit mode.
//-------------------------------------------------------------------------------
export async function ExitAoiEditMode(revertChangesFirst: boolean = false)
{
  if(useStore.getState().store_aoiUIMode !== 'edit') 
    return;  // Not in edit mode, nothing to do

  if(revertChangesFirst)
    await EditModeRevertAoi();

  // Remove the draw control from the map
  RemoveDrawControlFromMap();

  // Add back in the AOI boundary visual layer
  AddAoiLayerToMap();

  // Switch UI modes
  useStore.getState().store_setAoiUIMode('default');
}

//-------------------------------------------------------------------------------
// Zoom the map to the active AOI.
//-------------------------------------------------------------------------------
export function ZoomToActiveAOI()
{
  const store_aoi = useStore.getState().store_aoi;
  if(!store_aoi) return;

  if(store_aoi.updatedGeom)
    ZoomMapToGeojsonExtent(store_aoi.updatedGeom);
  else if(store_aoi.geom)
    ZoomMapToGeojsonExtent(store_aoi.geom);
}

//-------------------------------------------------------------------------------
// Add an AOI to the specified AOI group.
// NOTE: Used when importing an AOI from another project into the active project.
//-------------------------------------------------------------------------------
export async function AddAOIToAOIGroup(aoi_id: number, aoi_group_id: number): Promise<boolean>
{
  // Call the server

  const server = new CallServer();
  server.Add('aoi_id', aoi_id);
  server.Add('aoi_group_id', aoi_group_id);

  const result = await server.Call('put', '/aoi_group');

  if(result.success)
  {
    Debug.log('AoiOps.AddAOIToAOIGroup> API server call SUCCESS');

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to add new AOI to the active project")
    Debug.error('AoiOps.AddAOIToAOIGroup> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Export an AOI to a file.
// NOTE: The output filename will automatically be "aoiname.geojson".
//-------------------------------------------------------------------------------
export async function ExportAoi(aoi_id: number, exportFormat: TAoiExportFormat): Promise<boolean>
{
  // Call the server

  const server = new CallServer();
  server.Add('aoi_id', aoi_id);
  server.Add('format', exportFormat);

  useStore.getState().store_setAoiIsExporting(true);

  const result = await server.DownloadFile('/exportaoi');

  useStore.getState().store_setAoiIsExporting(false);
  
  if(result.success)
  {
    Debug.log('AoiOps.ExportAoi> API server call SUCCESS');

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to export the active AOI to file")
    Debug.error('AoiOps.ExportAoi> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}
