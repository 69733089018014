// UI component to select from a list of projects

import { Stack, Typography, Button, Box, IconButton } from "@mui/material";
import { theme_textColorBlended, theme_orange, theme_textColorMain, theme_limeGreen } from "../Theme";
import { DeleteProject, LoadProject, RemoveProjectBoundaryLayerFromMap } from "./ProjectOps";
import { IProjectListItem } from './ProjectInterfaces';
import useStore from "../store";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useState } from "react";
import YesNoDialog from "../Components/YesNoDialog";
import { DEV_MODE, FriendlyDateFromStr } from "../Globals";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import TypographyWithAutoTooltip from "../Components/TypograpyWithAutoTooltip";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ProjectSelectionProps
{
  calledFromProjCreation: boolean;
}

//-------------------------------------------------------------------------------
// Project selection component
//-------------------------------------------------------------------------------
const ProjectSelection = (props: ProjectSelectionProps) => 
{
  // Get needed state data from the store
   const { store_project, store_setProjectUIMode, store_orgProjectsList
         } = useStore();


  const [deleteProjectConfirmation, setDeleteProjectConfirmation] = useState(false);
  const [deleteProjectItem, setDeleteProjectItem] = useState<IProjectListItem|null>(null);





  //-------------------------------------------------------------------------------
  // Load a different saved project.
  //-------------------------------------------------------------------------------
  async function onLoadSavedProject(clickedProjectID: string)
  {
    // Exit project selection mode
    store_setProjectUIMode('default');

    // Load the clicked saved project
    LoadSavedProject(clickedProjectID);
  }

  //-------------------------------------------------------------------------------
  // Load a different saved project.
  //-------------------------------------------------------------------------------
  async function LoadSavedProject(projectID: string)
  {
    // If the active project was clicked, ignore the click
    if(store_project && store_project.project_id === projectID)
      return;

    RemoveProjectBoundaryLayerFromMap();

    await LoadProject(projectID);

    //const project = useStore.getState().store_project;

    // When a saved project is loaded, the 'active_project_id' in the user profile changes
    useStore.getState().store_setUserProfileIsDirty(true);

    // Something went wrong loading the project
    //if(!project || !project.bundle_id) return;

    //const store_bundle = useStore.getState().store_bundle;

    // If no bundle is loaded, or the current bundle is not the active bundle for the
    // project just loaded in, load in a new active bundle for this project
    // if(!store_bundle || (store_bundle.bundle_id !== project.bundle_id))
    //   LoadBundle(project.bundle_id, false);
  }
  
  //-------------------------------------------------------------------------------
  // Cancel switching projects.
  //-------------------------------------------------------------------------------
  const onCancelProjectSwitch = () => 
  {
    store_setProjectUIMode('default');
  }

  //-------------------------------------------------------------------------------
  // Delete a project (triggers user confirmation dialog).
  //-------------------------------------------------------------------------------
  async function onDeleteProject(projectListItem: IProjectListItem)
  {
    // Pop up the user confirmation dialog (if they hit Yes, that will trigger the actual delete)
    setDeleteProjectItem(projectListItem);
    setDeleteProjectConfirmation(true);
  }

  //-------------------------------------------------------------------------------
  // Delete a project (after user confirmation).
  //-------------------------------------------------------------------------------
  async function onDeleteProjectConfirmed()
  {
    // Close the confirmation dialog
    setDeleteProjectConfirmation(false);

    if(!deleteProjectItem) return;

    // Delete the project
    //
    // NOTE: This call auto-removes the project from 'store_projectList'

    await DeleteProject(deleteProjectItem.project_id);
  }

  //-------------------------------------------------------------------------------
  // Returns the total number of projects the user has.
  //-------------------------------------------------------------------------------
  function GetProjectCount(): number
  {
    if(!store_orgProjectsList || store_orgProjectsList.length === 0) return 0;

    let count = 0;
    for(let i=0; i < store_orgProjectsList.length; i++)
      count += store_orgProjectsList[i].projects.length;

    return count;
  }




  // Main render - error state

  if(!store_project || !store_orgProjectsList)
  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Project selection unhandled state
    </Box>    
  )

  // Main render

  return (

    <Stack direction='column' sx={{ width: '100%', alignItems: 'center' }}>

      <Typography sx={{ fontSize: '1.2rem', color: theme_textColorBlended }}>
        { props.calledFromProjCreation ? "Open a project" : "Open a different project" }
      </Typography>

      {GetProjectCount() === 0
        ?
          <div>
            {props.calledFromProjCreation === true
              ?
                <Typography sx={{ m: 1, fontSize: '0.8rem', color: theme_textColorMain, opacity: 0.6 }}>
                  You do not have any projects.
                </Typography>
              :null
            }
          </div>  
        :
          <Stack sx={{ width: '100%' }}>

            {props.calledFromProjCreation === false && GetProjectCount() === 1
              ?
                <Typography sx={{ m: 1, fontSize: '0.8rem', color: theme_textColorMain, opacity: 0.6 }}>
                  You have no other projects.
                </Typography>
              :
                // Show the list of existing projects

                <Stack direction='column' 
                       sx={{ width: '100%', alignItems: 'center', maxHeight: '300px', overflow: 'auto' }}>

                  {store_orgProjectsList.map(orgProjects =>
                    <Stack key={orgProjects.organization_id} sx={{ width: '100%' }}>

                      <Typography sx={{ mt: 1, mb: 0.3, color: theme_orange, fontSize: '1rem', textAlign: 'center' }}>
                        {orgProjects.org_name}
                      </Typography>

                      {orgProjects.projects.map(projectListItem =>

                        <Box key={projectListItem.project_id} sx={{ width: '100%' }}>
                          {projectListItem.project_id!==store_project.project_id 
                            ?
                              <Stack direction='row' sx={{ width: '100%' }}>
                                <Button variant='outlined' size='small' fullWidth
                                      sx={{ width: '100%', mt: '4px', mb: '4px', textTransform: 'none', borderRadius: 2,
                                      color: projectListItem.project_id===store_project.project_id ? theme_orange : theme_textColorMain,
                                      borderColor: projectListItem.project_id===store_project.project_id ? theme_orange : null }}
                                      onClick={() => onLoadSavedProject(projectListItem.project_id)}>
                                  <Stack sx={{ width: '100%' }}>
                                    {projectListItem.sharing_info
                                      ?
                                        <Stack sx={{ width: '100%' }}>
                                          {projectListItem.sharing_info.shared_new
                                            ?
                                              <Stack direction='row' sx={{ justifyContent: 'center' }}>
                                                <FiberNewIcon sx={{ color: '#e6ee9c', mr: '5px' }}/>
                                                  <TypographyWithAutoTooltip sx={{ fontSize: '0.8rem', maxWidth: '100%' }}>
                                                    {projectListItem.name}
                                                  </TypographyWithAutoTooltip>
                                              </Stack>
                                            :
                                              <TypographyWithAutoTooltip placement='right' arrow sx={{ fontSize: '0.8rem', maxWidth: '100%' }}>
                                                {projectListItem.name}
                                              </TypographyWithAutoTooltip>
                                            }

                                          <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, opacity: 0.75 }}>
                                            {/* Ex: Shared by Alex Turta on Jan 1, 2023 */}
                                            Shared by {projectListItem.sharing_info.shared_by_user_name} {FriendlyDateFromStr(projectListItem.sharing_info.shared_date)}
                                          </Typography>

                                        </Stack>
                                      :
                                        <TypographyWithAutoTooltip sx={{ fontSize: '0.8rem', maxWidth: '100%' }}>
                                          {projectListItem.name}
                                        </TypographyWithAutoTooltip>
                                    }

                                    {DEV_MODE
                                      ?
                                        <Typography sx={{ color: theme_textColorBlended, opacity: 0.75, fontSize: '0.5rem' }}>
                                          {projectListItem.project_id}
                                        </Typography>
                                      :null
                                    }
                                  </Stack>
                                </Button>
                                
                                <IconButton onClick={() => onDeleteProject(projectListItem)}
                                            disabled={projectListItem.project_id===store_project.project_id}
                                            sx={{ opacity: projectListItem.project_id!==store_project.project_id ? 1 : 0 }}>
                                  <DeleteForeverIcon sx={{ color: theme_textColorBlended, opacity: 0.6 }}/>
                                </IconButton>
                                
                              </Stack>

                            :null
                          }
                        </Box>

                        // <Stack key={projectListItem.project_id} direction='row' sx={{ width: '100%' }}>
                        //   <Button variant='outlined' size='small' fullWidth
                        //           sx={{ width: '100%', mt: '4px', mb: '4px', textTransform: 'capitalize',
                        //           color: projectListItem.project_id===store_project.project_id ? theme_orange : theme_textColorMain,
                        //           borderColor: projectListItem.project_id===store_project.project_id ? theme_orange : null }}
                        //           onClick={() => onLoadSavedProject(projectListItem.project_id)}>
                        //     {projectListItem.name}
                        //   </Button>

                        //   {/* NOTE: The active project cannot be deleted (disabled and invisible) */}
                        //   <IconButton onClick={() => onDeleteProject(projectListItem)}
                        //               disabled={projectListItem.project_id===store_project.project_id}
                        //               sx={{ opacity: projectListItem.project_id!==store_project.project_id ? 1 : 0 }}>
                        //     <DeleteForeverIcon sx={{ color: theme_textColorBlended, opacity: 0.6 }}/>
                        //   </IconButton>
                        // </Stack>
                      )}
                    </Stack>
                  )}

                </Stack>              
            }

          </Stack>
      }

      {/* Offer the user a cancel button to back out */}

      {!props.calledFromProjCreation
        ?
          <Button size='small' sx={{ mt: 1, fontSize: '1rem', color: theme_limeGreen }} 
                  onClick={onCancelProjectSwitch}>
            Cancel
          </Button>
        :null
      }

      {/* "Delete Project" Yes/No confirmation dialog */}

      <YesNoDialog open={deleteProjectConfirmation} setOpen={setDeleteProjectConfirmation}
                   onYes={onDeleteProjectConfirmed}>
        <Stack direction='row' sx={{ alignItems: 'center' }}>
          <DeleteForeverIcon sx={{ mr: 2, color: theme_textColorBlended }} />
          <Box sx={{ color: theme_textColorMain }}>Delete project</Box>
          <Box sx={{ ml: 1, color: theme_orange, fontWeight: 'bold' }}>{deleteProjectItem?.name}</Box>
          <Box sx={{ ml: 1, color: theme_textColorMain }}>?</Box>
        </Stack>
      </YesNoDialog>

    </Stack>    
  )
}

export default ProjectSelection;


