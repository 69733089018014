// Terms of service dialog

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { theme_bgColorLight, theme_bgColorLight1, theme_textColorBlended, theme_textColorMain } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { stratifyx_logo } from '../Theme';
import useStore from "../store";
import { PerformLogout } from "./UserOps";
import { SaveUserProfile } from "../UserProfile";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface TermsOfServiceDialogProps
{
}

//-------------------------------------------------------------------------------
// Terms of service dialog
//
// NOTE: This dialog is called in 2 different ways:
//       1) At login to force the user to Agree/Disagree to the TOS
//       2) From the user menu after they already agreed (just to review the TOS any time)
//-------------------------------------------------------------------------------
export function TermsOfServiceDialog(props: TermsOfServiceDialogProps) 
{
  // Get needed state data from the store
  const { store_isLoggedIn, store_userProfile, store_showTermsOfServiceWindow,
          store_setShowTermsOfServiceWindow,
        } = useStore();

  if(!store_isLoggedIn || !store_userProfile) return null;

  const userHasSignedTOS: boolean = store_userProfile.agreed_to_terms_of_service !== undefined && store_userProfile.agreed_to_terms_of_service === true;








  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const onClose = () => 
  {
    // If the user has not signed the TOS agreement, by closing this dialog it is assumed they Disagree to the TOS, so
    // we log them out.

    if(userHasSignedTOS === false)
    {
      PerformLogout();
      return;
    }

    // The user has already signed the TOS agreement, and are now just reviewing it again
    store_setShowTermsOfServiceWindow(false);
  }

  //-------------------------------------------------------------------------------
  // The user has agreed to the terms of service.
  //-------------------------------------------------------------------------------
  const onAgree = () => 
  {
    useStore.getState().store_setUserProfileAgreedToTermsOfService(true);
    useStore.getState().store_setUserProfileIsDirty(true);

    // Force-save the user profile (so the Terms of Service agreement does not come up again)
    SaveUserProfile();
  }




















  // Main render

  return (

    <Dialog maxWidth='lg' fullWidth onClose={onClose}
            open={!store_userProfile || store_userProfile.agreed_to_terms_of_service === undefined || 
                  store_userProfile.agreed_to_terms_of_service === false || store_showTermsOfServiceWindow === true}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ fontSize: '1.3rem', color: theme_textColorMain }}>
            StratifyX Terms of Service Agreement
          </Typography>

          <IconButton size="small" onClick={onClose}
                      sx={{ padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ p: 0, background: 'white', height: '90vh', overflow: 'hidden' }}>

        <Stack sx={{ alignItems: 'center' }}>
          <img src={stratifyx_logo} alt="" width='200px' height='100px'/>
        </Stack>

        <object style={{ background: '#FFFFFF', width: '100%', height: 'calc(100% - 100px)' }}
                data="https://sfx-app-layer-legend-images.s3.amazonaws.com/StratifyX%2BMSA%2B(Software)%2B03152024.htm">
        </object>

      </DialogContent>

      {/* Dialog bottom bar */}

      {userHasSignedTOS === false
        ?
          // The user has NOT signed the TOS yet - force them to choose either AGREE or DISAGREE

          <DialogActions sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'center', p: 2 }}>

            <Stack direction='row'>
              <Button variant='contained' onClick={onClose} sx={{ width: '200px', fontSize: '1.4rem',  bgcolor: theme_bgColorLight, textTransform: 'none', color: theme_textColorBlended }}>
                Disagree
              </Button>

              <Button variant='contained' onClick={onAgree} sx={{ ml: 5, width: '200px', fontSize: '1.4rem', bgcolor: theme_bgColorLight, textTransform: 'none', fontWeight: 'bold' }}>
                Agree
              </Button>
            </Stack>        
            
          </DialogActions>
        :
          // The user has already signed the TOS yet - a simple Close button is shown
          <DialogActions sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'center', p: 2 }}>
            <Button variant='contained' autoFocus onClick={onClose} sx={{ width: '200px', fontSize: '1.4rem', bgcolor: theme_bgColorLight, textTransform: 'none', color: theme_textColorBlended }}>
              Close
            </Button>
          </DialogActions>
      }

    </Dialog>
  )
}

