// Layer library group buttons

import { Button, ButtonGroup, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { LAYER_LIBRARY_DRAWER_WIDTH } from "./LayerLibrary";
import { theme_textColorBlended, theme_orange, theme_textColorMain } from "../Theme";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import useStore from "../store";
import { ILayerLibraryGroup } from "./LayerLibraryInterfaces";

export const SPECIAL_UNGROUPPED_LAYERS_GROUP_ID: number = -1;


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface LayerLibraryGroupButtonsProps
{
  filterByGroupID?: number;
  OnGroupButtonClick(layerLibraryGroup: ILayerLibraryGroup, clickedLayerGroupID: number|undefined): any;
  setShowCreateNewGroupWindow: any;
}

//-------------------------------------------------------------------------------
// Layer library group buttons
//-------------------------------------------------------------------------------
export function LayerLibraryGroupButtons(props: LayerLibraryGroupButtonsProps) 
{
  // Get needed state data from the store
  const { store_permCreateLayerLibraryGroup, store_layerLibraryGroups, store_userInfo, 
  } = useStore();






  //-------------------------------------------------------------------------------
  // Returns a list of all layer library groups (either custom groups, or non-custom groups).
  //-------------------------------------------------------------------------------
  function GetLayerLibraryGroups(isCustomGroup: boolean): ILayerLibraryGroup[]
  {
    const newList: ILayerLibraryGroup[] = [];

    for(let i=0; i < store_layerLibraryGroups.length; i++)
      if(store_layerLibraryGroups[i].isCustomGroup === isCustomGroup)
        newList.push(store_layerLibraryGroups[i]);

    return newList;
  }

















  if(!store_userInfo) return null;

  // Main render

  return (

    <Stack sx={{ mt: '70px', width: `${LAYER_LIBRARY_DRAWER_WIDTH-1}px`, alignItems: 'center' }}>

      {/* MAIN layer groups */}

      <ToggleButtonGroup size="small" value={props.filterByGroupID} orientation="vertical" sx={{ ml: '0px', width: '89%', alignItems: 'center' }}>

        {GetLayerLibraryGroups(false).map(layerLibraryGroup =>
          //store_layerLibraryGroups.map(layerLibraryGroup =>
          // NOTE: Need to show empty group too (since users can now create new groups and they start out empty)
          //GetNonEmptyLayerLibraryGroups().map(layerLibraryGroup =>
          <ToggleButton key={layerLibraryGroup.layer_library_group_id}
                        value={layerLibraryGroup.layer_library_group_id} 
                        //disabled={IsLayerLibraryGroupEmpty(layerLibraryGroup.layer_library_group_id)}
                        onClick={(_,v)=>props.OnGroupButtonClick(layerLibraryGroup,v)}
                        sx={{ width: '100%', bgcolor: '#2E5894A0', 
                        border: 1, borderColor: theme_textColorBlended+'A0', px: 0.1, py: 0.4 }}>
            <Stack>
              <Typography sx={{ textTransform: 'none', opacity: 0.8, fontSize: '0.8rem', 
                                color: props.filterByGroupID === layerLibraryGroup.layer_library_group_id ? theme_orange : theme_textColorMain }}>
                {layerLibraryGroup.name}
              </Typography>

              {props.filterByGroupID === layerLibraryGroup.layer_library_group_id
                ?
                  <Typography sx={{ textTransform: 'none', color: theme_textColorBlended, opacity: 0.7, fontSize: '0.6rem' }}>
                    (click again to remove filter)
                  </Typography>
                :null
              }
            </Stack>
          </ToggleButton>
        )}

      </ToggleButtonGroup>

      {/* CUSTOM layer groups */}

      <Stack direction='column' sx={{ width: '100%', mt: 1.5, alignItems: 'center' }}>

        {/* Title section - visible if the user has at least 1 group OR if the user has "Create Group" permissions  */}

        {GetLayerLibraryGroups(true).length > 0 || store_permCreateLayerLibraryGroup
          ?
            <Stack direction='row' sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <Typography sx={{ mr: 0.5, fontSize: '0.7rem', color: theme_textColorBlended }}>
                Custom Groups
              </Typography>

              <Tooltip arrow placement='right-start'
                title=
                {
                  <Stack sx={{ p: '0px', borderRadius: 1, maxWidth: '280px' }}>
                    <Typography sx={{ color: theme_textColorMain, opacity: 1, fontWeight: 'bold', fontSize: '1rem' }}>
                      Custom Layer Groups
                    </Typography>
                    <Typography sx={{ mt: 1, color: theme_textColorMain, opacity: 0.8, fontSize: '0.8rem', alignItems: 'center' }}>
                      Custom groups contain custom layers only available to your organization.  Layers within are visible to all users in your organization.
                    </Typography>
                </Stack>
                }>
                <InfoIcon sx={{ fontSize: '12px', color: theme_textColorBlended }}/>
              </Tooltip>
            </Stack>
          :null
        }

        {/* Custom layer group buttons */}

        <ToggleButtonGroup size="small" value={props.filterByGroupID} orientation="vertical" 
                           sx={{ mt: 0.6, ml: '0px', width: '89%', alignItems: 'center', mb: 2 ,
                                 overflow: 'auto', maxHeight: '150px' }}>

          {GetLayerLibraryGroups(true).map(layerLibraryGroup =>
            //store_layerLibraryGroups.map(layerLibraryGroup =>
            // NOTE: Need to show empty group too (since users can now create new groups and they start out empty)
            //GetNonEmptyLayerLibraryGroups().map(layerLibraryGroup =>
            <ToggleButton key={layerLibraryGroup.layer_library_group_id}
                          value={layerLibraryGroup.layer_library_group_id} 
                          //disabled={IsLayerLibraryGroupEmpty(layerLibraryGroup.layer_library_group_id)}
                          onClick={(_,v)=>props.OnGroupButtonClick(layerLibraryGroup,v)}
                          sx={{ width: '100%', bgcolor: '#2E5894A0', 
                          border: 1, borderColor: theme_textColorBlended+'A0', px: 0.1, py: 0.3 }}>
              <Stack>
                <Typography sx={{ textTransform: 'none', opacity: 0.8, fontSize: '0.7rem', 
                                  color: props.filterByGroupID === layerLibraryGroup.layer_library_group_id ? theme_orange : theme_textColorMain }}>
                  {layerLibraryGroup.name}
                </Typography>

                {props.filterByGroupID === layerLibraryGroup.layer_library_group_id
                  ?
                    <Typography sx={{ textTransform: 'none', color: theme_textColorBlended, opacity: 0.7, fontSize: '0.6rem' }}>
                      (click again to remove filter)
                    </Typography>
                  :null
                }
              </Stack>
            </ToggleButton>
          )}

        </ToggleButtonGroup>

        {/* Create New Group button */}

        {store_permCreateLayerLibraryGroup
          ?
            <ButtonGroup orientation="vertical" sx={{ width: '100%', alignItems: 'center', mt: 0 }}>
              <Button onClick={(_)=>props.setShowCreateNewGroupWindow(true)}
                      sx={{ width: '90%', bgcolor: theme_orange + '20', borderColor: theme_textColorBlended, justifyContent: 'space-around' }}>
                <AddCircleOutlineIcon sx={{ m: 0, p: 0, opacity: 0.8, width: '20px', height: '20px' }} />
                <Typography sx={{ textTransform: 'none', fontSize: '0.7rem', m: 0, ml: 0, p: 0, color: theme_textColorBlended, opacity: 1}}>
                  Create New Group
                </Typography>
              </Button>
            </ButtonGroup>
          :null
        }

      </Stack>

    </Stack>
  )
}

