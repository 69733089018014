// Map operations

import useStore from "../store";
import { ILayer } from "../Layers/LayerInterfaces";
import { GetGeojsonExtentBBox, IsPointInsidePolygon } from "../GisOps";
import { GetActiveParcelLayer, MapParcelClick } from "../Parcels/ParcelOps";
import { BaseMap } from "../BaseMapPanel";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import mapboxgl from "mapbox-gl";
import { MapScaleUnits } from "../AppSettingsDialog";
import { IProject } from "../Projects/ProjectInterfaces";
import { BBox, GeoJSON } from "geojson";
import { MapFeatureIdentifyClick } from "../Identify/IdentifyOps";
import * as turf from '@turf/turf'


export const MAPBOX_3D_TERRAIN_LAYER_SOURCE_NAME = 'mapbox-dem';

//-------------------------------------------------------------------------------
// Handles map clicks.
//-------------------------------------------------------------------------------
export async function MapClick(clickLongitude: number, clickLatitude: number, ctrlPressed: boolean)
{
  //Debug.log('MapOps.MapClick> ');


  // If the user clicked OUTSIDE of the project boundary, ignore the click
  
  const store_project = useStore.getState().store_project;
  if(!store_project) return null;
  if(store_project.boundary && IsPointInsidePolygon(clickLongitude, clickLatitude, store_project.boundary.boundaryGeoJSON) === false)
  {
    useStore.getState().store_setIdentify(undefined); // Close the identify panel if it was already open
    return;
  }

  // If the parcel layer is active we do a special parcel identify call which returns the
  // parcel geometry and selects/deselect the clicked parcel.  It also loads identify data
  // for the clicked parcel (displayed on the right side in the special parcel identify panel).
  //
  // If we are viewing parcel search results however, we disable this.  In that mode we want
  // clicks on parcels to just trigger the regular identify (we don't want select/deselect).

  const parcelLayer : ILayer | undefined = GetActiveParcelLayer();
  if(parcelLayer && useStore.getState().store_parcelSearchResultsViewerMode === false)
    MapParcelClick(parcelLayer, clickLongitude, clickLatitude, ctrlPressed);

  // Regular identify (if the parcel layer is active, it will include it as well)

  if(useStore.getState().store_aoiUIMode !== 'edit')
    MapFeatureIdentifyClick(clickLongitude, clickLatitude);
}

//-------------------------------------------------------------------------------
// Create and add the draw control to the map.
//-------------------------------------------------------------------------------
export function AddDrawControlToMap()
{
  const store_map = useStore.getState().store_map;
  const store_mapDrawControl = useStore.getState().store_mapDrawControl;
  const store_setMapDrawControl = useStore.getState().store_setMapDrawControl;

  if(!store_map) return;

  if(store_mapDrawControl && store_map.hasControl(store_mapDrawControl)) return;

  // Add the draw tool to the map

  const newMapDrawControl : MapboxDraw = new MapboxDraw(
    {
      displayControlsDefault: false,
      controls:
      {
        polygon: true,
        trash: true
      },
      boxSelect: false,
      // defaultMode: 'draw_polygon'
      // keybindings: "false",
      // userProperties: true,
      // modes: {
      //   ...MapboxDraw.modes
      // }
      // modes: Object.assign({
      //   direct_select: DrawRectangle
      // }, MapboxDraw.modes)
      //styles: []
    });

    store_map.addControl(newMapDrawControl, 'top-left');

    // Register the map draw control with the store
    store_setMapDrawControl(newMapDrawControl);
}

//-------------------------------------------------------------------------------
// Remove the draw control from the map.
//-------------------------------------------------------------------------------
export function RemoveDrawControlFromMap()
{
  const store_map = useStore.getState().store_map;
  const store_mapDrawControl = useStore.getState().store_mapDrawControl;
  const store_setMapDrawControl = useStore.getState().store_setMapDrawControl;

  if(store_map && store_mapDrawControl)
  {
    store_map.removeControl(store_mapDrawControl);
    store_setMapDrawControl(null);
  }
}

//-------------------------------------------------------------------------------
// Returns the opacity string apropriate for the specified Mapbox layer type.
//-------------------------------------------------------------------------------
export function GetOpacityStrForLayerType(layerType: string) : string
{
  switch(layerType)
  {
    //case 'icon-image': return 'icon-opacity';
    //case 'text-field': return 'text-opacity';
    case 'symbol': return 'text-opacity';
    default: return `${layerType}-opacity`;
  }
}

//-------------------------------------------------------------------------------
// Adds or removes the map scale control based on current user profile settings.
//-------------------------------------------------------------------------------
export function UpdateMapScaleState(newState: boolean, newMapScaleUnits: MapScaleUnits)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  const store_mapScaleControl = useStore.getState().store_mapScaleControl;
  const store_setMapScaleControl = useStore.getState().store_setMapScaleControl;

  // Remove the map scale control from the map

  if(store_mapScaleControl)
    store_map.removeControl(store_mapScaleControl);
  store_setMapScaleControl(null);

  // Update the map

  if(newState)
  {
    // Add the map scale control to the map

    const newMapScaleControl = new mapboxgl.ScaleControl({ unit: newMapScaleUnits });
    store_map.addControl(newMapScaleControl);

    // Register the new map scale control with the store
    store_setMapScaleControl(newMapScaleControl);
  }
}

//-------------------------------------------------------------------------------
// Update the map with the new 'geolocate' state.
//-------------------------------------------------------------------------------
export function UpdateMapGeolocateState(newState: boolean)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // Remove the map geolocate control from the map
  const store_mapGeolocateControl = useStore.getState().store_mapGeolocateControl;
  if(store_mapGeolocateControl)
    store_map.removeControl(store_mapGeolocateControl);

  if(newState)
  {
      // Add the control to the map

      const newMapGeolocateControl = new mapboxgl.GeolocateControl(
        {
          positionOptions: 
          {
            enableHighAccuracy: true
          },
          trackUserLocation: true,
          showUserHeading: true
        });
    
      store_map.addControl(newMapGeolocateControl, 'bottom-right');

      // Register the map geolocate control with the store
      useStore.getState().store_setMapGeolocateControl(newMapGeolocateControl);
  }
  else // Control is being removed
    useStore.getState().store_setMapGeolocateControl(null);
}

//-------------------------------------------------------------------------------
// Map compass setting was changed.
//-------------------------------------------------------------------------------
export function UpdateMapCompassState(newState: boolean)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // Remove the control from the map
  const store_mapCompassControl = useStore.getState().store_mapCompassControl;
  if(store_mapCompassControl)
    store_map.removeControl(store_mapCompassControl);

  if(newState)
  {
    // Add the map compass control to the map

    const newMapCompassControl = new mapboxgl.NavigationControl(
      {
        showCompass: true,
        showZoom: false, // maybe for touch screens, but those use pinch to zoom anyway?
        visualizePitch: true
      });

    store_map.addControl(newMapCompassControl, 'bottom-right');

    // Register the new map control with the store
    useStore.getState().store_setMapCompassControl(newMapCompassControl);
  }
  else // The control is being removed
    useStore.getState().store_setMapCompassControl(null);
}

//-------------------------------------------------------------------------------
// Map view has changed.
//-------------------------------------------------------------------------------
export function UpdateMapView()
{
  const store_project: IProject | null = useStore.getState().store_project;
  if(!store_project) return;
  
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  store_map.setCenter([store_project.user_settings.mapView.lng, store_project.user_settings.mapView.lat]);
  store_map.setZoom(store_project.user_settings.mapView.zoom);
  store_map.setPitch(store_project.user_settings.mapView.pitch);
  store_map.setBearing(store_project.user_settings.mapView.bearing);
}

//-------------------------------------------------------------------------------
// Fit the view bounds of the map to the extent of the specified geojson.
//-------------------------------------------------------------------------------
export async function ZoomMapToBBox(bbox: BBox)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // Make sure the geom is not empty
  if(!bbox || bbox.length !== 4)
    return undefined;

  // -79.69297143547524,
  // -75.10850023134684,
  // 33.60239481885454,
  // 36.55065428371902  

  // Navigate the map to the AOI area | SW-LNG, SW-LAT, NE-LNG, NE-LAT
  //store_map.fitBounds([bbox[0], bbox[2], bbox[1], bbox[3]], {padding: 50});
  //store_map.fitBounds([bbox[2], bbox[3], bbox[0], bbox[1]], {padding: 0});
  store_map.fitBounds(bbox, {padding: 85});
}

//-------------------------------------------------------------------------------
// Fit the view bounds of the map to the extent of the specified geojson.
//-------------------------------------------------------------------------------
export async function ZoomMapToGeojsonExtent(geom: GeoJSON)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // Make sure the geom is not empty
  if(!geom || (Object.keys(geom).length === 0 && geom.constructor === Object))
  return undefined;

  // Enlarge the AOI's extent by a factor - we don't want to zoom in all the way,
  // we want some space around the AOI for context.
  //
  // NOTE: The 'fitBounds' call below can have padding, but it's in pixels, which
  //       works differently are different horizontal resolutions.  On a 4k screen
  //       a 200 pixel marging is ok, but on a phone it's way too much.  So instead,
  //       we just enlarge the AOI a bit, then use that bbox as the map bounds.
  const enlargedAoiExtent = turf.transformScale(geom as any, 1.5);

  // Figure out the extent of the AOI
  const bbox = GetGeojsonExtentBBox(enlargedAoiExtent); // It should already be in WSG84/EPSG:4326 (lat/long)

  // const lngLatBounds: mapboxgl.LngLatBounds = new mapboxgl.LngLatBounds();
  // lngLatBounds.setSouthWest([bbox[2], bbox[3]]);
  // lngLatBounds.setNorthEast([bbox[0], bbox[1]]);

  // Convert the bbox from mercator to wsg84 (lat/lng)
  // const bboxWsg84: mapboxgl.LngLatBounds = new mapboxgl.LngLatBounds();
  // bboxWsg84.setNorthEast(turf.toWgs84([bboxMercator[0], bboxMercator[1]]));
  // bboxWsg84.setSouthWest(turf.toWgs84([bboxMercator[2], bboxMercator[3]]));



  // bbox: [NE-LNG, NE-LAT, SW-LNG, SW-LAT]

  // Navigate the map to the AOI area | SW-LNG, SW-LAT, NE-LNG, NE-LAT
  store_map.fitBounds([bbox[2], bbox[3], bbox[0], bbox[1]], {padding: 0});

  // Zoom up a bit to provide some padding on the sides.
  // NOTE: The 'fitBounds' call above can have padding, but it's pixels, which
  //       works differently are different horizontal resolutions.  On a 4k screen
  //       a 200 pixel marging is ok, but on a phone it's way too much.
  // NOTE2: Turns out on machine with good graphics, the fitBounds call above takes 
  //        too long, and the setZoom call cancels it.  So it doesn't go to the new 
  //        extent, it just zooms out a bit.
  //store_map.setZoom(store_map.getZoom() - 1); 
}

//-------------------------------------------------------------------------------
// Get the first base map symbol layer name.
//-------------------------------------------------------------------------------
export function GetMapSymbolLayerID(map: mapboxgl.Map, baseMap: BaseMap) : string | undefined
{
  // The Sattelite base map has no sub-layers
  if(baseMap === BaseMap.Satellite) return undefined;

  // NOTE:  The goal is to insert our layers on top of all the land and roads, 
  //        but below the boundaries and labels.  On all base maps, inserting
  //        just below the 'admin-1-boundary-bg' sub-layer achieves this goal.

  for (const layer of map.getStyle().layers)
  {
    //Debug.log(`${layer.id} (${layer.type})`);

    if(layer.id === 'admin-1-boundary-bg')
      return layer.id;
  }

  return undefined;  // not found
}

//-------------------------------------------------------------------------------
// DEBUG: Output all current layers to the clipboard.
//-------------------------------------------------------------------------------
export function OutputAllLayersToClipboard()
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  let outputStr: string = '';

  for (const layer of store_map.getStyle().layers)
    outputStr += `${layer.id} (${layer.type})\n` 
    //Debug.log(`${layer.id} (${layer.type})`);

  navigator.clipboard.writeText(outputStr);
}

//-------------------------------------------------------------------------------
// Enable or disable 3D terrain.
//-------------------------------------------------------------------------------
export function Enable3DTerrain(newEnableState: boolean = true)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  const alreadyEnabled : boolean = store_map.getSource(MAPBOX_3D_TERRAIN_LAYER_SOURCE_NAME) ? true : false;

  // If we're already in the desired state, do nothing
  if((newEnableState && alreadyEnabled) || (!newEnableState && !alreadyEnabled)) return;

  if(newEnableState)
  {
    // Enable 3D terrain

    store_map.addSource(MAPBOX_3D_TERRAIN_LAYER_SOURCE_NAME,
    {
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      'tileSize': 512,
      'maxzoom': 14
    });

    store_map.setTerrain({ 'source': MAPBOX_3D_TERRAIN_LAYER_SOURCE_NAME, 'exaggeration': 1.5 });
  }
  else
  {
    // Disable 3D terrain

    //store_map.setFog({}); // Doesn't work
    store_map.setTerrain();
    store_map.removeSource(MAPBOX_3D_TERRAIN_LAYER_SOURCE_NAME);
  }
}

//-------------------------------------------------------------------------------
// Enable or disable fog effects.
//-------------------------------------------------------------------------------
export function EnableFog(newEnableState: boolean = true)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  if(newEnableState)
    store_map.setFog(
      {
        'horizon-blend': 0.3,
        color: '#f8f0e3',
        range: [ 3, 12],
      });
  else
    store_map.setFog({}); // Doesn't seem to work
}

//-------------------------------------------------------------------------------
// Change the 3D terrain exaggeration factor.
//-------------------------------------------------------------------------------
export function Change3DTerrainExaggerationFactor(newValue: number)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  store_map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': newValue });
}
