// Allows the user to change the base map

import { Stack, Typography, Box, IconButton } from "@mui/material";
import useStore from "../store";
import LayersList from "./LayersList";
import { BaseMapLayerItem } from '../BaseMapLayerItem';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LabelIcon from '@mui/icons-material/Label';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import React from "react";
import { BaseMap } from "../BaseMapPanel";
import { theme_orange, theme_textColorBlended } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import WaterIcon from '@mui/icons-material/Water';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface LayersPanelProps
{
  onClose: any;
}

//-------------------------------------------------------------------------------
// Map property display component
//-------------------------------------------------------------------------------
const LayersPanel = (props: LayersPanelProps) => 
{
  // Get needed state data from the store
  const { store_map, store_baseMap, store_baseMapLayer_labels, store_baseMapLayer_states,
          store_baseMapLayer_roads, store_baseMapLayer_water, store_activateBaseMapLayer,
        } = useStore();












  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function GetBaseMapBundleLayer(baseMapBundleName: string) : IBaseMapLayer | null
  {
    if(!store_map) return null;

    switch(baseMapBundleName.toLowerCase())
    {
      case 'labels': return store_baseMapLayer_labels;
      case 'states': return store_baseMapLayer_states;
      case 'roads': return store_baseMapLayer_roads;
      case 'water': return store_baseMapLayer_water;
      default: return null;
    }
  }

  //-------------------------------------------------------------------------------
  // Show/hide the specified base map bundle layer.
  //-------------------------------------------------------------------------------
  function SetBaseMapBundleLayerVisibility(baseMapBundleName: string, visible: boolean)
  {
    if(!store_map) return;

    let baseMapBundleLayer = GetBaseMapBundleLayer(baseMapBundleName);
    if(!baseMapBundleLayer) return;

    store_map.getStyle().layers.forEach((layer) => 
    {
      // Check if any of the match string are found in this layer's name

      let matchFound = false;
      baseMapBundleLayer?.subLayerMatchList.forEach(matchStr => 
      {
        if(layer.id.includes(matchStr))
        {
          matchFound = true;
          return;
        }
      })

      // If a match was found, change the visibility of that sub-layer
      if(matchFound)
        store_map.setLayoutProperty(layer.id, 'visibility', visible ? 'visible' : 'none');
    });
  }
/*
  //-------------------------------------------------------------------------------
  // Set opacity for a specified base map layer.
  //-------------------------------------------------------------------------------
  function SetBaseMapLayerOpacity(baseMapBundleName: string, newOpacityValue: number)
  {
    if(!store_map) return;

    let baseMapBundleLayer = GetBaseMapBundleLayer(baseMapBundleName);
    if(!baseMapBundleLayer) return;

    store_map.getStyle().layers.forEach((layer) => 
    {
      // Check if any of the match string are found in this layer's name

      let matchFound = false;
      baseMapBundleLayer?.subLayerMatchList.forEach(matchStr => 
      {
        if(layer.id.includes(matchStr))
        {
          matchFound = true;
          return;
        }
      })

      // If a match was found, change the opacity of that sub-layer
      if(matchFound)
        store_map.setPaintProperty(layer.id, GetOpacityStrForLayerType(layer.type), newOpacityValue);
    });
  }
*/
  //-------------------------------------------------------------------------------
  // The user clicked on a base map sub-layer bundle check box.
  //-------------------------------------------------------------------------------
  const onBaseMapBundleCheckBox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    if(!store_map) return;

    // Update the store state
    store_activateBaseMapLayer(event.target.name, checked);

    // Update the actual map
    SetBaseMapBundleLayerVisibility(event.target.name, checked);
  }



  // Main render

  return (

    <Stack direction='row' sx={{ py: '5px', pl: '6px', pr: '3px' }}>

      {/* Layers sub-panel */}

      <Stack direction='column'>

        {/* Title and close button */}

        <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>

          <Typography sx={{ width:'100%', height: '36px', fontSize: '1.6rem', ml: '5px', mb: '5px', color: theme_orange }}>
            Layers
          </Typography>

          <IconButton size="small" onClick={props.onClose}
                      sx={{ mr: '4px', padding: 0, width: '32px', height: '32px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '25px', height: '25px', color: theme_textColorBlended }} />
          </IconButton>
          
        </Stack>

        <Box sx={{ width: '100%', height: '2px', borderRadius: 1, bgcolor: 'white', opacity: 0.5 }}/>

        {/* Layers list */}

        <Stack sx={{ mt: '6px', maxHeight: 'calc(80vh - 100px)', overflow: 'auto' }}>

          {/* Basemap special layers: Labels, State Lines, and Roads.
              NOTE: Not available for the Satellite base map. */}

          {store_baseMap !== BaseMap.Satellite
            ?
              <Stack direction='row' sx={{ mt: '0px', mb: '10px', justifyContent: 'center' }}>
                <Stack direction='column' sx={{ }}>
                  <BaseMapLayerItem layer={store_baseMapLayer_labels} onChange={onBaseMapBundleCheckBox}
                                    MuiIcon={<LabelIcon sx={{ color: '#fdd835', opacity: 0.7, width: '14px', height: '14px', mr: '3px' }}/>}/>
                  <BaseMapLayerItem layer={store_baseMapLayer_states} onChange={onBaseMapBundleCheckBox}
                                    MuiIcon={<PowerInputIcon sx={{ color: '#dddddd', opacity: 0.7, width: '14px', height: '14px', mr: '3px' }}/>}/>
                </Stack>
                <Stack direction='column' sx={{ ml: 2  }}>
                  <BaseMapLayerItem layer={store_baseMapLayer_roads} onChange={onBaseMapBundleCheckBox}
                                    MuiIcon={<DirectionsCarIcon sx={{ color: '#aed581', opacity: 0.7, width: '14px', height: '14px', mr: '3px' }}/>}/>
                  <BaseMapLayerItem layer={store_baseMapLayer_water} onChange={onBaseMapBundleCheckBox}
                                    MuiIcon={<WaterIcon sx={{ color: '#33eaff', opacity: 0.7, width: '14px', height: '14px', mr: '3px' }}/>}/>
                </Stack>
              </Stack>
            :null
          }

          {/* Layer list */}
          <LayersList />

        </Stack>
      </Stack>

    </Stack>
  );
}









//-------------------------------------------------------------------------------
// Resets the special base map bundle layers to their defaults.
//-------------------------------------------------------------------------------
export function ResetBaseMapLayers()
{
  const store_setBaseMapLayer = useStore.getState().store_setBaseMapLayer;

  store_setBaseMapLayer('Labels' , 1, [ '-label' ]);
  store_setBaseMapLayer('States' , 1, [ '-boundary' ]);
  store_setBaseMapLayer('Roads'  , 1, [ 'road-', 'traffic-', 'bridge-', 'tunnel-', 'ferry', 'aerialway', 'aeroway-' ]);
  store_setBaseMapLayer('Water'  , 1, [ 'water' ]);
}





// STYLE cutomization for MUI ToggleButton
// const ToggleButtonStyled = styled(ToggleButton)(
// {
//   //backgroundColor: '#eceff1',

//   // Colors when a base map is selected
//   "&.Mui-selected, &.Mui-selected:hover": 
//   {
//     color: "#263238",
//     backgroundColor: '#b0bec5'
//   }
// });

// Represents a bundle of base map sub-layers (roads, water, labels, boundaries, etc)
export interface IBaseMapLayer
{
  name: string;
  enabled: boolean;
  opacity: number;
  expanded: boolean;
  subLayerMatchList: string[];
}



export default LayersPanel;