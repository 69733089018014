import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CallServer } from '../CallServer';
import { ValidateFirstName, ValidateLastName, ValidateEmail, ValidatePassword } from './UserOps';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton, Stack } from '@mui/material';
import { stratifyx_logo, theme_errorRed } from '../Theme';
import Debug from '../Debug';
import AccountPageBase from '../Components/AccountPageBase';




//-------------------------------------------------------------------------------
// Sign up new user
//-------------------------------------------------------------------------------
export default function SignUp()
{
  const [errorMessage, setErrorMessage] = useState('');
  const [signUpSuccess, setSignUpSuccess] = useState<boolean>(false);
  
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [lastNameErrorText, setLastNameErrorText] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordErrorText, setPasswordErrorText] = useState<string>('');

  //const [showServiceAgreement, setShowServiceAgreement] = useState<boolean>(true);

  //const navigate = useNavigate();

  // NOTE: These are for the "show/hide password" feature
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);


  //-------------------------------------------------------------------------------
  // Submit.
  //-------------------------------------------------------------------------------
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => 
  {
    event.preventDefault();

    setErrorMessage('');

    // Get form data and validate

    const data = new FormData(event.currentTarget);

    let validationErrMsg = '';

    const firstName: string = data.get('firstName')!.toString();
    validationErrMsg = ValidateFirstName(firstName);
    setFirstNameError(validationErrMsg !== '');
    setFirstNameErrorText(validationErrMsg);
    if(validationErrMsg !== '') return;

    const lastName: string = data.get('lastName')!.toString();
    validationErrMsg = ValidateLastName(lastName);
    setLastNameError(validationErrMsg !== '');
    setLastNameErrorText(validationErrMsg);
    if(validationErrMsg !== '') return;

    const email: string = data.get('email')!.toString();
    validationErrMsg = ValidateEmail(email);
    setEmailError(validationErrMsg !== '');
    setEmailErrorText(validationErrMsg);
    if(validationErrMsg !== '') return;

    const password: string = data.get('password')!.toString();
    validationErrMsg = ValidatePassword(password);
    setPasswordError(validationErrMsg !== '');
    setPasswordErrorText(validationErrMsg);
    if(validationErrMsg !== '') return;

    // Call server to create the new user

    const server = new CallServer(false);
    server.Add('email', email);
    server.Add('first_name', firstName);
    server.Add('last_name', lastName);
    server.Add('password', password);

    server.Call('post', '/user').then(result => 
    {
      if(result.success)
      {
        Debug.log('SUCCESS! User account created');

        setErrorMessage('');

        // Signal that the user sign-up worked.
        //
        // This will trigger the return of a new page which tells the user the new 
        // account was created ok and provides a button to go to the login page.

        setSignUpSuccess(true);  
      }
      else
      {
        Debug.error('FAILURE! err=' + result.errorCode + ' - ' + result.errorMessage);

        if(result.errorMessage === '{"detail":"This user has not been invited to join."}')
        {
          setErrorMessage('The email address you used has not been invited to create an account.');
        }
        else
          setErrorMessage('Unable to create new user');
      }
    });
  }




  // The Sign-Up terms of service agreement is popped up

  // if(showServiceAgreement)
  // return (
  //   <SignUpTermsWindow showServiceAgreement={showServiceAgreement} setShowServiceAgreement={setShowServiceAgreement}/>
  // )




  // If the sign-up worked, return a page which tells the user the new 
  // account is created and provide a button to go to the login page.

  if (signUpSuccess)
  return (

    <AccountPageBase>

      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0 }}>
        <img src={stratifyx_logo} alt="" height='150px' />
        <Typography sx={{ fontSize: '1.3rem', color: '#1976D2', mt: 2 }}>
          Your StratifyX account has been created.
        </Typography>
        <Typography sx={{ fontSize: '1.3rem', color: '#1976D2', mt: 2 }}>
          Please proceed to log in.
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3, mb: 3 }}>
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} component={RouterLink} to="/Account/LogIn" >
            Log In
          </Button>
        </Box>
      </Box>

    </AccountPageBase>
  )

  // Main render

  return (

    <AccountPageBase>

      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0 }}>

        <img src={stratifyx_logo} alt="" height='150px' />

        <Typography sx={{ fontSize: '1.5rem', color: '#1976D2' }}>
          Create a new StratifyX account
        </Typography>

        <Typography sx={{ fontSize: '0.8rem', color: theme_errorRed, mt: 2, width: '90%' }}>
          This app is currently invite-only.  If you've received an invite, please sign up using that same email address.
        </Typography>

        <Stack direction='row' sx={{ mt: 1, width: '90%', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '0.7rem', color: 'black', opacity: 0.5}}>
            To request an invite, please email
          </Typography>
          <Link href="mailto:jason@stratifyx.com" target="_top" rel="noopener noreferrer" sx={{ fontSize: '0.7rem', ml: '4px' }}>
            jason@stratifyx.com
          </Link>
        </Stack>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <TextField autoComplete="given-name" name="firstName" required fullWidth
                         id="firstName" label="First Name" autoFocus 
                         helperText={firstNameErrorText} error={firstNameError}/>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField required fullWidth id="lastName" label="Last Name" name="lastName" 
                         autoComplete="family-name" helperText={lastNameErrorText} 
                         error={lastNameError}/>
            </Grid>

            <Grid item xs={12}>
              <TextField required fullWidth id="email" label="Email Address" name="email" 
                         autoComplete="email" helperText={emailErrorText} error={emailError}/>
            </Grid>

            <Grid item xs={12}>
              <TextField required fullWidth name="password" label="Password" 
                         id="password" autoComplete="new-password" helperText={passwordErrorText} 
                         error={passwordError} 
                         type={showPassword ? "text" : "password"}
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end">
                               <IconButton aria-label="toggle password visibility"
                                 onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                 {showPassword ? <Visibility /> : <VisibilityOff />}
                               </IconButton>
                             </InputAdornment>
                           )
                        }}/>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive marketing promotions and updates via email."
              />
            </Grid> */}

            <Grid item xs={12}>
              <Typography color='error.main' alignContent='center'>
                {errorMessage}
              </Typography>
            </Grid>

          </Grid>
          
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }} >
            Sign Up
          </Button>

          <Grid container justifyContent="center">
            <Grid item>

              {/* <Button variant='text' color="inherit" sx={{ color: '#1976D5', textTransform: 'none' }} onClick={() => navigate("/Account/LogIn")} >
                Already have an account? Log in
              </Button> */}

              {/* <Button variant='text' color="inherit" component={RouterLink} to={"/Account/LogIn"} sx={{ color: '#1976D5', textTransform: 'none' }}>
                Already have an account? Log in
              </Button> */}

              {/* <Link component={RouterLink} to="/Account/LogIn">
                Already have an account? Log in
              </Link> */}

              <Link href="/Account/LogIn" variant="body2">
                Already have an account? Log in
              </Link>

            </Grid>
          </Grid>
        </Box>

        <Link href="/" variant="body2" sx={{ mt: 5, mb: 1 }}>Back to App</Link>

      </Box>

    </AccountPageBase>
  )
}

