// Edit layer style

import { Checkbox, MenuItem, Select, SelectChangeEvent, Slider, Stack, Typography, styled } from "@mui/material";
import { theme_errorRed, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../../Theme";
import { ILayer, ILayerStyleInfo, TLayerStylePointType } from '../../Layers/LayerInterfaces';
import React, { ChangeEvent, ReactNode } from 'react';
import { CustomTextField } from './EditLayer';
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
import { FriendlyNumber } from "../../Globals";


const SLIDER_MIN_VALUE = 1;
const SLIDER_MAX_VALUE = 20;
const VISIBLE_ZOOM_RANGE_SLIDER_MIN_DISTANCE = 2;

const MIN_WIDTH = 3;
const MAX_WIDTH = 30;
const MIN_OUTLINE_SIZE = 0;
const MAX_OUTLINE_SIZE = 15;
const MIN_LABEL_FONT_SIZE = 8;
const MAX_LABEL_FONT_SIZE = 72;
const MIN_LABEL_HALO_SIZE = 0.5;
const MAX_LABEL_HALO_SIZE = 4;


const ZOOM_SLIDER_MARKS = 
[
  {
    value: 1,
    scaledValue: 100,
    label: "1:100"
  },
  {
    value: 2,
    scaledValue: 250,
    label: "1:250"
  },
  {
    value: 3,
    scaledValue: 500,
    label: "1:500"
  },
  {
    value: 4,
    scaledValue: 1000,
    label: "1:1,000"
  },
  {
    value: 5,
    scaledValue: 2000,
    label: "1:2,000"
  },
  {
    value: 6,
    scaledValue: 3500,
    label: "1:3,500"
  },
  {
    value: 7,
    scaledValue: 7000,
    label: "1:7,000"
  },
  {
    value: 8,
    scaledValue: 13000,
    label: "1:13,000"
  },
  {
    value: 9,
    scaledValue: 25000,
    label: "1:25,000"
  },
  {
    value: 10,
    scaledValue: 50000,
    label: "1:50,000"
  },
  {
    value: 11,
    scaledValue: 100000,
    label: "1:100,000"
  },
  {
    value: 12,
    scaledValue: 175000,
    label: "1:175,000"
  },
  {
    value: 13,
    scaledValue: 300000,
    label: "1:300,000"
  },
  {
    value: 14,
    scaledValue: 600000,
    label: "1:600,000"
  },
  {
    value: 15,
    scaledValue: 1000000,
    label: "1:1 million"
  },
  {
    value: 16,
    scaledValue: 2000000,
    label: "1:2 million"
  },
  {
    value: 17,
    scaledValue: 4000000,
    label: "1:4 million"
  },
  {
    value: 18,
    scaledValue: 8000000,
    label: "1:8 million"
  },
  {
    value: 19,
    scaledValue: 15000000,
    label: "1:15 million"
  },
  {
    value: 20,
    scaledValue: 30000000,
    label: "1:30 million"
  },
];


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditLayerStyleProps
{
  layer?: ILayer;
  newStyleInfo?: ILayerStyleInfo;
  setNewStyleInfo: any;
  setStyleWasChanged: any;
}

//-------------------------------------------------------------------------------
// Edit layer
//-------------------------------------------------------------------------------
export function EditLayerStyle(props: EditLayerStyleProps) 
{

  // const [minDenominator, setMinDenominator] = useState(0);
  // const [maxDenominator, setMaxDenominator] = useState(0);




  //-------------------------------------------------------------------------------
  // Handles clamping values for the zoom range sliders (for both the geom slider and text label slider).
  //
  // It forces the 2 sliders to never overlap and have a certain distance between them
  // as they are dragged around.
  //-------------------------------------------------------------------------------
  function GetZoomRangeSliderClampedValues(value_0: number, value_1: number, activeThumb: number): number[]
  {
    if (value_1 - value_0 < VISIBLE_ZOOM_RANGE_SLIDER_MIN_DISTANCE) 
    {
      if (activeThumb === 0) 
      {
        const clamped = Math.min(value_0, SLIDER_MAX_VALUE - VISIBLE_ZOOM_RANGE_SLIDER_MIN_DISTANCE);
        return [clamped, clamped + VISIBLE_ZOOM_RANGE_SLIDER_MIN_DISTANCE];
      } 
      else 
      {
        const clamped = Math.max(value_1, SLIDER_MIN_VALUE + VISIBLE_ZOOM_RANGE_SLIDER_MIN_DISTANCE);
        return [clamped - VISIBLE_ZOOM_RANGE_SLIDER_MIN_DISTANCE, clamped];
      }
    } 
    else
      return [value_0, value_1];
  }

  //-------------------------------------------------------------------------------
  // The zoom range (geometry) slider has changed.
  //-------------------------------------------------------------------------------
  const OnZoomRangeForGeomSliderChanged = (event: Event, newValue: number | number[], activeThumb: number) => 
  {
    if (!Array.isArray(newValue)) return;

    const min_max_scale_values: number[] = GetZoomRangeSliderClampedValues(newValue[0], newValue[1], activeThumb);
    const min_scale = min_max_scale_values[0];
    const max_scale = min_max_scale_values[1];

    // We also need to compute the scaled values (the values that will go into the
    // Geoserver <MinScaleDenominator> and <MaxScaleDenominator> tags).
    //
    // If the MIN is all the way to the left (=1), then set the scaled value to undefined (meaning no min).
    // If the MAX is all the way to the right (=20), then set the scaled value to undefined (meaning no max).

    const min_scale_denominator: number|undefined = min_scale === SLIDER_MIN_VALUE ? undefined : ZOOM_SLIDER_MARKS[min_scale-1].scaledValue;
    const max_scale_denominator: number|undefined = max_scale === SLIDER_MAX_VALUE ? undefined : ZOOM_SLIDER_MARKS[max_scale-1].scaledValue;

    // Update the style
    props.setNewStyleInfo(
      {
        ...props.newStyleInfo, 
        min_scale: min_scale, 
        max_scale: max_scale,
        min_scale_denominator: min_scale_denominator, 
        max_scale_denominator: max_scale_denominator
      });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The zoom range (text labels) slider has changed.
  //-------------------------------------------------------------------------------
  const OnZoomRangeForLabelsSliderChanged = (event: Event, newValue: number | number[], activeThumb: number) => 
  {
    if (!Array.isArray(newValue)) return;

    const label_min_max_scale_values: number[] = GetZoomRangeSliderClampedValues(newValue[0], newValue[1], activeThumb);
    const label_min_scale = label_min_max_scale_values[0];
    const label_max_scale = label_min_max_scale_values[1];

    // We also need to compute the scaled values (the values that will go into the
    // Geoserver <MinScaleDenominator> and <MaxScaleDenominator> tags).
    //
    // If the MIN is all the way to the left (=1), then set the scaled value to undefined (meaning no min).
    // If the MAX is all the way to the right (=20), then set the scaled value to undefined (meaning no max).

    const label_min_scale_denominator: number|undefined = label_min_scale === SLIDER_MIN_VALUE ? undefined : ZOOM_SLIDER_MARKS[label_min_scale-1].scaledValue;
    const label_max_scale_denominator: number|undefined = label_max_scale === SLIDER_MAX_VALUE ? undefined : ZOOM_SLIDER_MARKS[label_max_scale-1].scaledValue;

    // Update the style
    props.setNewStyleInfo(
      {
        ...props.newStyleInfo, 
        label_min_scale: label_min_scale, 
        label_max_scale: label_max_scale,
        label_min_scale_denominator: label_min_scale_denominator, 
        label_max_scale_denominator: label_max_scale_denominator
      });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The width has changed.
  //-------------------------------------------------------------------------------
  function OnWidthChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newWidthStr: string = event.target.value;

    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, width: newWidthStr });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The color has changed.
  //-------------------------------------------------------------------------------
  function OnColorChanged(value: string, colors: MuiColorInputColors): void 
  {
    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, color: value });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The outline width has changed.
  //-------------------------------------------------------------------------------
  function OnOutlineWidthChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newWidthStr: string = event.target.value;

    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, outline_width: newWidthStr });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }
  
  //-------------------------------------------------------------------------------
  // The outline color has changed.
  //-------------------------------------------------------------------------------
  function OnOutlineColorChanged(value: string, colors: MuiColorInputColors): void 
  {
    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, outline_color: value });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The outline checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnOutlineCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, outline_enabled: checked });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The text label checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnTextLabelCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, label_enabled: checked });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }  

  //-------------------------------------------------------------------------------
  // The point type combo box was changed.
  //-------------------------------------------------------------------------------
  const OnPointTypeChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, point_type: event.target.value as TLayerStylePointType });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The text label attribute name combo box was changed.
  //-------------------------------------------------------------------------------
  const OnLabelAttributeChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, label_attrib: event.target.value as string });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }
      
  //-------------------------------------------------------------------------------
  // The text label font size has changed.
  //-------------------------------------------------------------------------------
  function OnLabelFontSizeChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValueStr: string = event.target.value;

    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, label_size: newValueStr });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }
  
  //-------------------------------------------------------------------------------
  // The text label color has changed.
  //-------------------------------------------------------------------------------
  function OnLabelColorChanged(value: string, colors: MuiColorInputColors): void 
  {
    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, label_color: value });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The text label "Is Bold" checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnLabelBoldCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, label_bold: checked });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The text label "Halo Enabled" checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnLabelHaloCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
    {
      // Set the new value
      props.setNewStyleInfo({...props.newStyleInfo, label_halo_enabled: checked });
  
      // Tell calling code that the style was changed (so it knows to save it)
      props.setStyleWasChanged(true);
    }
    
  //-------------------------------------------------------------------------------
  // The text label halo size has changed.
  //-------------------------------------------------------------------------------
  function OnLabelHaloSizeChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newSizeStr: string = event.target.value;

    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, label_halo_size: newSizeStr });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }
  
  //-------------------------------------------------------------------------------
  // The text label halo color has changed.
  //-------------------------------------------------------------------------------
  function OnLabelHaloColorChanged(value: string, colors: MuiColorInputColors): void 
  {
    // Set the new value
    props.setNewStyleInfo({...props.newStyleInfo, label_halo_color: value });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setStyleWasChanged(true);
  }



/*
function ConvertZoomToGSDenominator(input: number): number
{
  // Let's say you have a linear range xmin..xmax and want to map it logarithmically onto X0..X1.
  //
  // The equation is:
  //
  // X = a + b * ln (x)

  const xmin = 0.01;
  const xmax = 100;
  const X0 = 30000000;
  const X1 = 200;

  // b = (X1 - X0) / ln(xmax / xmin)
  const b = (X1 - X0) / Math.log(xmax / xmin);

  // a = X0 - b * ln(xmin)
  const a = X0 - b * Math.log(xmin);

  // X = a + b * ln(x)
  return a + b * Math.log(input);
}
*/

  //-------------------------------------------------------------------------------
  // Returns the text labels to use for the zoom range sliders.
  //-------------------------------------------------------------------------------
  function ScaleSliderLabelFormatter(num: number) 
  {
    return (
      <Stack direction='row' sx={{ alignItems: 'center' }}>
        <Typography sx={{ color: 'white', fontSize: '0.8rem' }}>
          Scale
        </Typography>
        <Typography sx={{ ml: 0.6, fontWeight: 'bold', color: theme_orange, fontSize: '0.9rem' }}>
          1
        </Typography>
        <Typography sx={{ fontWeight: 'bold', color: 'white', opacity: 0.8, mx: 0.2, fontSize: '0.8rem' }}>
          :
        </Typography>
        <Typography sx={{ color: theme_orange, fontWeight: 'bold', fontSize: '0.9rem' }}>
          {FriendlyNumber(ZOOM_SLIDER_MARKS[num-1].scaledValue)}
        </Typography>
      </Stack>
    );
  }



  // function GetSliderValueArray(layer_style: ILayerStyleInfo)
  // {
  //   // Convert the scaled up value stored in the db to our slider's 1-20 linear range
  //   const value1: number | undefined = ZOOM_SLIDER_MARKS.find(item => item.scaledValue===layer_style.max_scale)?.value;
  //   if(!value1) return [];

  //   const value2: number | undefined = ZOOM_SLIDER_MARKS.find(item => item.scaledValue===layer_style.min_scale)?.value;
  //   if(!value2) return [];

  //   return [value1, value2];
  // }







  

  if(!props.layer) return null;

  if(!props.layer.isCustomLayer) return (
    <Typography sx={{ mt: 3, fontSize: '1.5rem', color: theme_errorRed, textAlign: 'center', opacity: 0.7 }}>
      This layer's style cannot be edited.
    </Typography>
  )

  if(!props.newStyleInfo) return null;

  // Main render

  return (

    <Stack>

      {/* Point Style (optional), Width, and Color */}

      <Stack direction='row'>

        {/* Point type */}

        {props.newStyleInfo.shape_type === 'point'
          ?
            <Stack sx={{ mr: 4 }}>

              <Typography sx={{ mt: '-2px', fontSize: '0.8rem', color: theme_textColorBlended+'B0' }}>
                Point Style
              </Typography>
              
              <CustomSelect id="point-type-select" variant='standard' size='small'
                            value={props.newStyleInfo.point_type} onChange={OnPointTypeChanged}
                            sx={{ width: '115px', mt: '-2px', mb: 2, fontSize: '1.1rem' }}>
                <MenuItem key='circle' value='circle'>
                  <Stack direction='row'>
                    <Typography sx={{ width: '25px', color: theme_limeGreen+'C0' }}>⬤</Typography>
                    <Typography sx={{ color: theme_textColorMain+'C0' }}>Circle</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem key='square' value='square'>
                  <Stack direction='row'>
                    <Typography sx={{ width: '25px', color: theme_limeGreen+'C0' }}>◼</Typography>
                    <Typography sx={{ color: theme_textColorMain+'C0' }}>Square</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem key='diamond' value='diamond'>
                  <Stack direction='row'>
                    <Typography sx={{ width: '25px', color: theme_limeGreen+'C0' }}>◆</Typography>
                    <Typography sx={{ color: theme_textColorMain+'C0' }}>Diamond</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem key='triangle' value='triangle'>
                  <Stack direction='row'>
                    <Typography sx={{ width: '25px', color: theme_limeGreen+'C0' }}>▲</Typography>
                    <Typography sx={{ color: theme_textColorMain+'C0' }}>Triangle</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem key='star' value='star'>
                  <Stack direction='row'>
                    <Typography sx={{ width: '25px', color: theme_limeGreen+'C0' }}>★</Typography>
                    <Typography sx={{ color: theme_textColorMain+'C0' }}>Star</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem key='cross' value='cross'>
                  <Stack direction='row'>
                    <Typography sx={{ width: '25px', color: theme_limeGreen+'C0' }}>✚</Typography>
                    <Typography sx={{ color: theme_textColorMain+'C0' }}>Cross</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem key='x' value='x'>
                  <Stack direction='row' sx={{ alignItems: 'center' }}>
                    <Typography sx={{ width: '25px', color: theme_limeGreen+'C0', fontSize: '0.7rem', fontWeight: 'bold' }}>╳</Typography>
                    <Typography sx={{ color: theme_textColorMain+'C0' }}>Cross</Typography>
                  </Stack>
                </MenuItem>
              </CustomSelect>
            </Stack>
          :null
        }

        {/* Width */}

        <CustomTextField name='styleWidth' variant='standard' size='small' autoComplete='off'
                          inputProps={{ type: 'number' }}
                          value={props.newStyleInfo.width} onChange={OnWidthChanged}
                          label=
                          {
                            <Typography sx={{color: theme_textColorBlended+'B0'}}>
                              {props.newStyleInfo.shape_type==='point'?'Point':'Line'} Width ({MIN_WIDTH}-{MAX_WIDTH})
                            </Typography>
                          } 
                          sx={{ p: 0, width: '105px' }}/>

        {/* Color */}

        <MuiColorInputStyled name='styleColor' variant='standard' size='small' format='hex' 
                          value={props.newStyleInfo.color} onChange={OnColorChanged}
                          label=
                          {
                            <Typography sx={{color: theme_textColorBlended+'B0'}}>
                              {props.newStyleInfo.shape_type==='point'?'Point':'Line'} Color
                            </Typography>
                          }
                          sx={{ ml: 5, width: '130px' }}/>

      </Stack>

      {/* Outline */}

      <Stack direction='column' sx={{ mt: 1 }}>

        <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'left' }}>
          <Checkbox size='small' checked={props.newStyleInfo.outline_enabled===true}
                    onChange={(e,c)=>OnOutlineCheckBoxChange(e,c)}
                    sx=
                    {{ 
                      ml: '-10px', mr: 0, color: theme_textColorBlended, width: '40px', height: '40px',
                      '&.Mui-checked': { color: theme_textColorBlended } 
                    }} />
          <Typography sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
            Outline (Halo)
          </Typography>
        </Stack>

        <Stack direction='row' sx={{ mt: 1, ml: 4, opacity: props.newStyleInfo.outline_enabled === true ? 1 : 0.7 }}>

          {/* Outline Width */}

          <CustomTextField name='outlineWidth' variant='standard' size='small' autoComplete='off' 
                           inputProps={{ type: 'number' }}
                           disabled={props.newStyleInfo.outline_enabled!==true}
                           value={props.newStyleInfo.outline_width} onChange={OnOutlineWidthChanged}
                           label={<Typography sx={{color: theme_textColorBlended+'B0'}}>Outline Size ({MIN_OUTLINE_SIZE}-{MAX_OUTLINE_SIZE})</Typography>} 
                           sx={{ p: 0, width: '105px' }}/>

          {/* Outline Color */}
          
          <MuiColorInputStyled name='outlineColor' variant='standard' size='small' format='hex' 
                               disabled={props.newStyleInfo.outline_enabled!==true}
                               value={props.newStyleInfo.outline_color} onChange={OnOutlineColorChanged}
                               label={<Typography sx={{color: theme_textColorBlended+'B0'}}>Outline Color</Typography>} 
                               sx={{ ml: 4, width: '130px' }} />
        </Stack>

      </Stack>

      {/* Visible Zoom Range slider */}

      <Stack sx={{ mt: 3 }}>

        <Typography sx={{ color: theme_textColorBlended+'B0', fontSize: '1.0rem' }}>
          Visible Zoom Range
        </Typography>

        <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '0.7rem' }}>
          Too many visible geometry objects when zoomed out can result in slow map performance.
        </Typography>

        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          <Stack sx={{ width: '80%', justifyContent: 'center', alignItems: 'center' }}>

            <Slider disableSwap min={SLIDER_MIN_VALUE} max={SLIDER_MAX_VALUE} step={1}
                    value={[props.newStyleInfo.min_scale, props.newStyleInfo.max_scale]} 
                    onChange={OnZoomRangeForGeomSliderChanged}
                    valueLabelFormat={ScaleSliderLabelFormatter} valueLabelDisplay='auto'
                    marks={ZOOM_SLIDER_MARKS}
                    sx={{ color: '#889645', width: '100%',
                          // this Hides the tick labels (too crowded and not needed)
                          '& .MuiSlider-markLabel': { color: 'transparent' }
                      }}/>

            <Stack direction='row' sx={{ width: '100%', mt: -2, justifyContent: 'space-between' }}>
              <Stack direction='column' sx={{ alignItems: 'center', ml: -5 }}>
                <Typography sx={{ color: theme_orange, opacity: 0.7, fontSize: '0.8rem' }}>
                  Fully Zoomed IN
                </Typography>
                <Typography sx={{ color: theme_orange, opacity: 0.5, fontSize: '0.7rem' }}>
                  (street level)
                </Typography>
              </Stack>
              <Stack direction='column' sx={{ alignItems: 'center', mr: -7 }}>
                <Typography sx={{ color: theme_orange, opacity: 0.7, fontSize: '0.8rem' }}>
                  Fully Zoomed OUT
                </Typography>
                <Typography sx={{ color: theme_orange, opacity: 0.5, fontSize: '0.7rem' }}>
                  (continental US)
                </Typography>
              </Stack>
            </Stack>

          </Stack>
        </Stack>

      </Stack>

      {/* Text Label Checkbox */}

      <Stack direction='column' sx={{ mt: 3 }}>

        <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'left' }}>
          <Checkbox size='medium' checked={props.newStyleInfo.label_enabled} 
                    onChange={(e,c)=>OnTextLabelCheckBoxChange(e,c)}
                    sx=
                    {{ 
                      ml: '-10px', mr: 0, color: theme_textColorBlended, width: '40px', height: '40px',
                      '&.Mui-checked': { color: theme_textColorBlended } 
                    }} />
          <Typography sx={{ color: theme_textColorBlended, opacity: 1, fontSize: '1.2rem' }}>
            Text Label
          </Typography>

        </Stack>
      </Stack>

      {/* Text label control container */}

      <Stack sx={{ mt: 1.5, ml: 4, opacity: props.newStyleInfo.label_enabled===true ? 1 : 0.5 }}>

        {/* Text label: attribute name */}

        <Stack>

          <Typography sx={{ mt: '-2px', fontSize: '0.8rem', color: theme_textColorBlended+'B0' }}>
            Attribute
          </Typography>

          <CustomSelect id="point-type-select" variant='standard' size='small'
                        value={props.newStyleInfo.label_attrib} onChange={OnLabelAttributeChanged}
                        disabled={props.newStyleInfo.label_enabled!==true}
                        sx={{ mt: '-3px', width: '75%' }}>
            {props.layer?.attributes?.map(attrib =>
              attrib.attribute_name.toLowerCase() !== 'geom' && attrib.attribute_name.toLowerCase() !== 'geometry' && attrib.attribute_name.toLowerCase() !== 'shape'
                ?
                  <MenuItem key={attrib.attribute_name} value={attrib.attribute_name}>
                    <Stack direction='row'>
                      <Typography sx={{ color: theme_textColorMain+'C0', fontSize: '0.9rem' }}>
                        {attrib.display_name}
                      </Typography>
                    </Stack>
                  </MenuItem>
                :null
            )}
          </CustomSelect>
        </Stack>        

        {/* Text label: font size, color, bold */}

        <Stack direction='row' sx={{ mt: 3, opacity: props.newStyleInfo.outline_enabled === true ? 1 : 0.7 }}>

          {/* Text Label Font Size */}

          <CustomTextField name='outlineWidth' variant='standard' size='small' autoComplete='off'
                          inputProps={{ type: 'number' }} 
                            disabled={props.newStyleInfo.label_enabled!==true}
                            value={props.newStyleInfo.label_size} onChange={OnLabelFontSizeChanged}
                            label={<Typography sx={{color: theme_textColorBlended+'B0'}}>Font Size ({MIN_LABEL_FONT_SIZE}-{MAX_LABEL_FONT_SIZE})</Typography>} 
                            sx={{ p: 0, width: '95px' }}/>

          {/* Text Label Color */}

          <MuiColorInputStyled name='outlineColor' variant='standard' size='small' format='hex' 
                                disabled={props.newStyleInfo.label_enabled!==true}
                                value={props.newStyleInfo.label_color} onChange={OnLabelColorChanged}
                                label={<Typography sx={{color: theme_textColorBlended+'B0'}}>Text Color</Typography>} 
                                sx={{ ml: 4, width: '130px' }} />

          {/* Text Label Bold */}

          <Stack direction='row' sx={{ ml: 4, alignItems: 'center', justifyContent: 'left' }}>
            <Checkbox size='small' checked={props.newStyleInfo.label_bold===true}
                      disabled={props.newStyleInfo.label_enabled!==true}
                      onChange={(e,c)=>OnLabelBoldCheckBoxChange(e,c)}
                      sx=
                      {{ 
                        ml: '-10px', mr: 0, color: theme_textColorBlended, width: '40px', height: '40px',
                        '&.Mui-checked': { color: theme_textColorBlended } 
                      }}/>
            <Typography sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
              Bold
            </Typography>
          </Stack>

        </Stack>

        {/* Text Label HALO controls */}

        <Stack direction='row' 
                sx={{ mt: 2, alignItems: 'center', justifyContent: 'left', 
                      opacity: props.newStyleInfo.label_halo_enabled === true ? 1 : 0.7 }}>

          {/* Text Label HALO checkbox */}

          <Checkbox size='small' checked={props.newStyleInfo.label_halo_enabled===true}
                    disabled={props.newStyleInfo.label_enabled!==true}
                    onChange={(e,c)=>OnLabelHaloCheckBoxChange(e,c)}
                    sx=
                    {{ 
                      ml: '-10px', mr: 0, color: theme_textColorBlended, width: '40px', height: '40px',
                      '&.Mui-checked': { color: theme_textColorBlended } 
                    }}/>
          <Typography sx={{ color: theme_textColorBlended, fontSize: '0.9rem' }}>
            Text Halo
          </Typography>

          {/* Text Label Halo Size */}

          <CustomTextField name='labelHaloSize' variant='standard' size='small' autoComplete='off'
                            inputProps={{ type: 'number' }} 
                            disabled={props.newStyleInfo.label_enabled!==true || props.newStyleInfo.label_halo_enabled!==true}
                            value={props.newStyleInfo.label_halo_size} onChange={OnLabelHaloSizeChanged}
                            label={<Typography sx={{color: theme_textColorBlended+'B0'}}>Text Halo Size ({MIN_LABEL_HALO_SIZE} - {MAX_LABEL_HALO_SIZE})</Typography>} 
                            sx={{ ml: 4, p: 0, width: '125px' }}/>

          {/* Text Label Halo Color */}

          <MuiColorInputStyled name='labelHaloColor' variant='standard' size='small' format='hex' 
                                disabled={props.newStyleInfo.label_enabled!==true || props.newStyleInfo.label_halo_enabled!==true}
                                value={props.newStyleInfo.label_halo_color} onChange={OnLabelHaloColorChanged}
                                label={<Typography sx={{color: theme_textColorBlended+'B0'}}>Text Halo Color</Typography>} 
                                sx={{ ml: 4, width: '130px' }} />
        </Stack>

        {/* Visible Text Label Zoom Range slider */}

        <Stack sx={{ mt: 3 }}>

          <Typography sx={{ color: theme_textColorBlended+'B0', fontSize: '1.0rem' }}>
            Text Label Visible Zoom Range
          </Typography>

          <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '0.7rem' }}>
            Too many visible text labels when zoomed out can result in slow map performance.
          </Typography>

          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            <Stack sx={{ width: '80%', justifyContent: 'center', alignItems: 'center' }}>
            
              <Slider disableSwap min={SLIDER_MIN_VALUE} max={SLIDER_MAX_VALUE} valueLabelDisplay='auto' step={1}
                      disabled={props.newStyleInfo.label_enabled!==true}
                      value={[props.newStyleInfo.label_min_scale, props.newStyleInfo.label_max_scale]} 
                      onChange={OnZoomRangeForLabelsSliderChanged}
                      valueLabelFormat={ScaleSliderLabelFormatter} 
                      marks={ZOOM_SLIDER_MARKS}
                      sx={{ color: '#889645', width: '100%',
                            // this Hides the tick labels (too crowded and not needed)
                            '& .MuiSlider-markLabel': { color: 'transparent' }
                         }}/>

              <Stack direction='row' sx={{ width: '100%', mt: -2, justifyContent: 'space-between' }}>
                <Stack direction='column' sx={{ alignItems: 'center', ml: -5 }}>
                  <Typography sx={{ color: theme_orange, opacity: 0.7, fontSize: '0.8rem' }}>
                    Fully Zoomed IN
                  </Typography>
                  <Typography sx={{ color: theme_orange, opacity: 0.5, fontSize: '0.7rem' }}>
                    (street level)
                  </Typography>
                </Stack>
                <Stack direction='column' sx={{ alignItems: 'center', mr: -7 }}>
                  <Typography sx={{ color: theme_orange, opacity: 0.7, fontSize: '0.8rem' }}>
                    Fully Zoomed OUT
                  </Typography>
                  <Typography sx={{ color: theme_orange, opacity: 0.5, fontSize: '0.7rem' }}>
                    (continental US)
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}






//-------------------------------------------------------------------------------
// Validate the layer style.
// Returns an error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
export function ValidateLayerStyle(newLayerStyle: ILayerStyleInfo|undefined): string
{
  if(!newLayerStyle) return 'Invalid layer style';

  if(!newLayerStyle.width)
    return 'The width cannot be empty';

  if(newLayerStyle.width < MIN_WIDTH || newLayerStyle.width > MAX_WIDTH)
    return `Invalid width (range is ${MIN_WIDTH} to ${MAX_WIDTH})`;

  if(!newLayerStyle.outline_width)
    return 'The outline size cannot be empty';

  if(newLayerStyle.outline_width < MIN_OUTLINE_SIZE || newLayerStyle.outline_width > MAX_OUTLINE_SIZE)
    return `Invalid outline size (range is ${MIN_OUTLINE_SIZE} to ${MAX_OUTLINE_SIZE})`;

  if(newLayerStyle.label_enabled && !newLayerStyle.label_attrib)
    return 'You must select an attribute for the text label (or disable text labels)';

  if(!newLayerStyle.label_size)
    return 'The text label font size cannot be empty';

  if(newLayerStyle.label_size < MIN_LABEL_FONT_SIZE || newLayerStyle.label_size > MAX_LABEL_FONT_SIZE)
    return `Invalid text label font size (range is ${MIN_LABEL_FONT_SIZE} to ${MAX_LABEL_FONT_SIZE})`;

  if(!newLayerStyle.label_halo_size)
    return 'The text label halo size cannot be empty';

  if(newLayerStyle.label_halo_size < MIN_LABEL_HALO_SIZE || newLayerStyle.label_halo_size > MAX_LABEL_HALO_SIZE)
    return `Invalid text label halo size (range is ${MIN_LABEL_HALO_SIZE} to ${MAX_LABEL_HALO_SIZE})`;

  // Validated
  return '';
}




// Custom style for the color picker
export const MuiColorInputStyled = styled(MuiColorInput)(
{
  // Main text color
  '& .MuiInputBase-root': 
  {
    color: theme_textColorMain+'C0',
  },

  '& .MuiColorInput-Popover': 
  {
    bgcolor: 'red',
    color: 'green',
  },

  // These change the underline color when using the standard variant
  '& .MuiInput-underline:before': { borderBottomColor: theme_textColorBlended },
  '& .MuiInput-underline:after': { borderBottomColor: theme_textColorBlended },    
});

// Customized MUI Select
export const CustomSelect = styled(Select)(() => (
{
  fontSize: '0.9rem', 
  color: theme_textColorMain, 
  opacity: 0.8,

  // These change the underline color when using the standard variant
  ':before': { borderBottomColor: theme_textColorBlended },
  ':after': { borderBottomColor: theme_textColorBlended },

  '& .MuiSvgIcon-root': { color: theme_textColorBlended }
}));


