import { TableRow, TableCell, Stack, Typography } from "@mui/material";
import { ILayer, IVectorLayerAttribute } from "../Layers/LayerInterfaces";
import { theme_bgColorMain, theme_textColorBlended, theme_limeGreen } from "../Theme";
import { IIdentifyFeature } from "./IdentifyInterfaces";
import AttributeHelp from "../Components/AttributeHelp";
import { GetVectorLayerAttribute, RenderVectorLayerAttributeValue } from "../AttributeOps";
import useStore from "../store";


interface IdentifyTableRowProps
{
  layer: ILayer;
  attribute_name: string;
  feature: IIdentifyFeature;
}

//-------------------------------------------------------------------------------
// Identify TableRow render component.
//-------------------------------------------------------------------------------
const IdentifyTableRow = (props: IdentifyTableRowProps) => 
{
  // Get needed state data from the store
  const { store_project, 
  } = useStore();

  
  const attrib: IVectorLayerAttribute | undefined = GetVectorLayerAttribute(props.layer, props.attribute_name);
  if(!attrib) return null;







  //-------------------------------------------------------------------------------
  // Render a single attribute value (renders appropriately given the attribute's data type).
  //-------------------------------------------------------------------------------
  function RenderAttribValue()
  {
    if(!props.feature || !props.feature.data || !attrib) return null;

    return RenderVectorLayerAttributeValue(attrib, props.feature.data[attrib.attribute_name]);
  }




  // Option to skip attributes with empty values.
  if(store_project && store_project.user_settings && store_project.user_settings.identify_showEmptyValues === false && 
     props && props.feature && props.feature.data &&
     (props.feature.data[attrib.attribute_name] === undefined || props.feature.data[attrib.attribute_name].length === 0))
    return null;

  // Main render

  return (
    
    <TableRow key={props.attribute_name} sx={{ paddingTop: '0px', paddingBottom: '0px' }}>

      <TableCell sx={{ bgcolor: theme_bgColorMain, p: 0, pl: 0.4, pr: 0.4 }}>
        <Stack direction='row' sx={{ alignItems: 'center' }}>
          <AttributeHelp attribute={attrib} />
          <Typography sx={{ ml: '3px', color: theme_textColorBlended, fontSize: '0.7rem' }}>
            {attrib.display_name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ bgcolor: theme_bgColorMain, color: theme_limeGreen, fontSize: '0.7rem', p: 0, pl: 0.2, pr: 0.2 }}>
        <RenderAttribValue/>
      </TableCell>

    </TableRow>
  )
}

export default IdentifyTableRow;
