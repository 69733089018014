// Bundle operations

import { useStore } from "../store";
import { CallServer } from "../CallServer";
import Debug from "../Debug";
import { LoadAllLayersFromServerCall } from "../Layers/LayerOps";
import { INRR, INRRImpact } from "./BundleInterfaces";
import { ToastNotification } from "../ToastNotifications";
import { IProject, IProjectUserSettingsNrrImpactState, IProjectUserSettingsNrrState } from "../Projects/ProjectInterfaces";
import { ILayerLibraryGroup } from "../LayerLibrary/LayerLibraryInterfaces";
import { SPECIAL_UNGROUPPED_LAYERS_GROUP_ID } from "../LayerLibrary/LayerLibraryGroupButtons";
/*
//-------------------------------------------------------------------------------
// Load the list of bundles for this user.
//-------------------------------------------------------------------------------
export async function LoadBundleList(): Promise<boolean>
{
  // Call the server to get the data

  const server = new CallServer();

  useStore.getState().store_setBundleListIsLoading(true); // Tell the UI the bundle list is loading

  const result = await server.Call('get', '/bundles');

  useStore.getState().store_setBundleListIsLoading(false); // Tell the UI the bundle list is done loading

  if(result.success)
  {
    Debug.log(`Bundles.LoadBundleList> Loaded ${result.data.length} bundles`);
    //Debug.log('Project.LoadProjectList> SUCCESS! data=' + JSON.stringify(result.data));
    
    // Update the state store
    useStore.getState().store_setBundleList(result.data);

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load bundle list")
    Debug.error('Bundles.LoadBundleList> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}
*/
//-------------------------------------------------------------------------------
// Load the specified bundle.
//-------------------------------------------------------------------------------
export async function LoadBundle(bundleID: number, projectID: string): Promise<boolean>
{
  // Need the project_id (the bundle returns layer urls, and those need to include boundary data, which is
  // based on the project).

  const userProfile = useStore.getState().store_userProfile;
  if(!userProfile) 
  {
    Debug.error('BundleOps.LoadBundle> Invalid user profile');
    return false;
  }

  // Make sure to turn off bundle selection mode (so the "is loading" state takes priority)
  useStore.getState().store_setBundleUIMode('default');

  // Call the server to get the data

  const server = new CallServer();
  server.Add("bundle_id", bundleID);
  server.Add("boundary_id", bundleID);
  server.Add("project_id", projectID);  // needed so the API call can determine the boundary for each layer URL

  useStore.getState().store_setBundleIsLoading(true); // Signal the UI that we are loading a new bundle

  const result = await server.Call('get', '/bundle');

  useStore.getState().store_setBundleIsLoading(false); // Signal the UI that we are done loading a new bundle

  if(result.success)
  {
    Debug.log('BundleOps.LoadBundle> API server call SUCCESS');
    //Debug.log('BundleOps.LoadBundle> SUCCESS! data=' + JSON.stringify(result.data));

    // Provide an initial value for "expanded" (so it's not undefined)
    let loadedNrrs: INRR[] = result.data.nrrs;
    for(let i=0; i < loadedNrrs.length; i++)
      loadedNrrs[i].expanded = false;

    // Sort the NRR list (by name)
    loadedNrrs = loadedNrrs.sort((a: INRR, b: INRR) => a.name.localeCompare(b.name));

    // Sort the layer library group list (by name)
    result.data.layer_library_groups = result.data.layer_library_groups.sort((a: ILayerLibraryGroup, b: ILayerLibraryGroup) => a.name.localeCompare(b.name));

    // Set the "isCustomGroup" for each layer library group
    // NOTE: Currently it is considered a custom group if the info json has a 'create_user_id' key.
    for(let i=0; i < result.data.layer_library_groups.length; i++)
    {
      const group = result.data.layer_library_groups[i];
      result.data.layer_library_groups[i].isCustomGroup = (group.info && group.info.create_user_id) ? true : false;
    }

    // If the user has the 'EditMainLayer' permission, add a special group that we will use locally 
    // to keep track of layers that have no group assigned.  This allows the layer library to show 
    // these "lost" layers to admin accounts, so they can be moved into a group or deleted.
    const layer_library_groups: ILayerLibraryGroup[] = result.data.layer_library_groups;

    if(result.data.EditMainLayer ?? false)
      layer_library_groups.push(
        {
          layer_library_group_id: SPECIAL_UNGROUPPED_LAYERS_GROUP_ID,
          name: '[ Layers with no group ]',
          info: undefined,
          CreateCustomLayer: false,
          EditLayerLibraryGroup: false,
          DeleteLayerLibraryGroup: false,
          isCustomGroup: false
        });

    // Update the state store
    useStore.getState().store_setBundle(result.data.bundle);
    useStore.getState().store_setNrrs(loadedNrrs);
    useStore.getState().store_setNrrImpacts(result.data.impacts);
    useStore.getState().store_setNrrImpactLinks(result.data.nrr_impact_links);
    useStore.getState().store_setProjectActiveBundle(bundleID);
    useStore.getState().store_setLayerLibraryGroups(layer_library_groups);
    useStore.getState().store_setPermCreateLayerLibraryGroup(result.data.CreateLayerLibraryGroup ??  false);
    useStore.getState().store_setPermEditMainLayer(result.data.EditMainLayer ?? false);
    
    LoadAllLayersFromServerCall(result.data.layers);

    // Success
    Debug.log(`BundleOps.LoadBundle> Bundle ${bundleID} loaded.`);
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load bundle")
    Debug.error('BundleOps.LoadBundle> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Get an NRR Impact for a specified nrr impact id.
//-------------------------------------------------------------------------------
export function GetNrrImpact(impact_id: number): INRRImpact | null
{
  const impacts: INRRImpact[] = useStore.getState().store_nrrImpacts;

  for(let i=0; i < impacts.length; i++)
    if(impacts[i].impact_id === impact_id)
      return impacts[i];

  return null;  // not found
}

//-------------------------------------------------------------------------------
// Get a specific bundle list item.
//-------------------------------------------------------------------------------
// function GetBundleListItem(bundleID: number): IBundleListItem | null
// {
//   const bundleList: IBundleListItem[] = useStore.getState().store_bundleList;

//   for(let i=0; i < bundleList.length; i++)
//     if(bundleList[i].bundle_id === bundleID)
//       return bundleList[i];

//   return null;
// }

//-------------------------------------------------------------------------------
// Get the NRR state for a specified nrr_id.
//-------------------------------------------------------------------------------
export function GetNrrState(nrr_id: number): IProjectUserSettingsNrrState | null
{
  const activeProject: IProject | null = useStore.getState().store_project;
  if(!activeProject) return null;

  for(let i=0; i < activeProject.user_settings.nrrStates.length; i++)
    if(activeProject.user_settings.nrrStates[i].nrr_id === nrr_id)
      return activeProject.user_settings.nrrStates[i];

  return null;
}

//-------------------------------------------------------------------------------
// Get the NRR impact state for a specified nrr_id and impact id.
//-------------------------------------------------------------------------------
export function GetNrrImpactState(nrr_id: number, impact_id: number): IProjectUserSettingsNrrImpactState | null
{
  const activeProject: IProject | null = useStore.getState().store_project;
  if(!activeProject) return null;

  const nrrStates: IProjectUserSettingsNrrState[] = activeProject.user_settings.nrrStates;
  if(!nrrStates) return null;

  for(let i=0; i < nrrStates.length; i++)
    if(nrrStates[i].nrr_id === nrr_id)
      for(let j=0; j < nrrStates[i].impactStates.length; j++)
        if(nrrStates[i].impactStates[j].nrr_impact_id === impact_id)
          return nrrStates[i].impactStates[j];

  return null;
}

//-------------------------------------------------------------------------------
// Get the NRR impact state for a specified nrr_id and impact id.
//-------------------------------------------------------------------------------
export function GetNrrName(nrr_id: number | undefined): string
{
  if(!nrr_id) return '';

  const nrrs: INRR[] = useStore.getState().store_nrrs;

  const nrr: INRR | undefined = nrrs.find(nrr => nrr.nrr_id === nrr_id);
  if(nrr)
    return nrr.short_name;

  return '';
}

//-------------------------------------------------------------------------------
// Get the impact name for a specified impact id.
//-------------------------------------------------------------------------------
export function GetImpactName(impact_id: number | undefined): string
{
  if(!impact_id) return '';

  const impacts: INRRImpact[] = useStore.getState().store_nrrImpacts;

  const impact: INRRImpact | undefined = impacts.find(impact => impact.impact_id === impact_id);
  if(impact)
    return impact.short_name;

  return '';
}

