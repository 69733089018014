import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COGNITO_PWD_RULE_SPECIAL_CHARS, ResetPassword_Step1, ResetPassword_Step2 } from './UserOps';
import { Button, Grid, IconButton, InputAdornment, Link, Stack, TextField, Tooltip } from '@mui/material';
import { stratifyx_logo } from '../Theme';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useStore from '../store';
import AccountPageBase from '../Components/AccountPageBase';



//-------------------------------------------------------------------------------
// Forgot password component.
//-------------------------------------------------------------------------------
export default function ForgotPassword()
{
  // Get needed state data from the store
  const { store_pwdResetRunningStep1, store_pwdResetRunningStep2, 
        } = useStore();


  const [errorMessage, setErrorMessage] = useState('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [confirmCodeMode, setConfirmCodeMode] = useState(false);
  const [processCompleteMode, setProcessCompleteMode] = useState(false);

  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPasswordResetCode, setConfirmPasswordResetCode] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [pwdRule8Chars, setPwdRule8Chars] = useState<boolean>(false);
  const [pwdRule1UpChar, setPwdRule1UpChar] = useState<boolean>(false);
  const [pwdRule1LowChar, setPwdRule1LowChar] = useState<boolean>(false);
  const [pwdRule1NumChar, setPwdRule1NumChar] = useState<boolean>(false);
  const [pwdRule1SpecialChar, setPwdRule1SpecialChar] = useState<boolean>(false);
  const [pwdRuleConfirm, setPwdRuleConfirm] = useState<boolean>(false);


  // NOTE: These are for the "show/hide password" feature
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);


  //-------------------------------------------------------------------------------
  // Step 1: Initiate password reset by sending the user a password reset code (email or text).
  //-------------------------------------------------------------------------------
  const OnPwdResetStep1Click = async () => 
  {
    // Clear any previous error messages
    setErrorMessage('');

    // Make sure a valid email address was specified
    if(!userEmail || userEmail.length === 0)
    {
      setErrorMessage('Please enter the email address you used to create the account');
      return;
    }

    // Initiate the password reset process - a reset code is sent to the user (via email or SMS)

    const status: boolean = await ResetPassword_Step1(userEmail);
    if(!status)
    {
      setErrorMessage('Unable to initiate password reset');
      return;
    }

    // The password reset was initiated successfully - switch the UI into code confirmation mode
    setConfirmCodeMode(true);
  }

  //-------------------------------------------------------------------------------
  // Step 2: Confirm the reset code and change the password.
  //-------------------------------------------------------------------------------
  const OnPwdResetStep2Click = async () => 
  {
    // Clear any previous error messages
    setErrorMessage('');

    // Make sure a password reset code was specified
    if(!confirmPasswordResetCode || confirmPasswordResetCode.length === 0)
    {
      setErrorMessage('Please enter the password reset code');
      return;
    }

    // Make sure all new password validation rules are met
    if(!pwdRule8Chars || !pwdRule1UpChar || !pwdRule1LowChar || !pwdRule1NumChar || !pwdRule1SpecialChar || !pwdRuleConfirm)
    {
      setErrorMessage('Please make sure the new password follows all the listed rules');
      return;
    }

    // Call server to attempt the password change

    const status: boolean = await ResetPassword_Step2(userEmail, confirmPasswordResetCode, newPassword);
    if(!status)
    {
      setErrorMessage('Unable to reset password');
      return;
    }

    // Password change worked - switch the UI mode
    setProcessCompleteMode(true);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the "Confirm Password Reset Code" text field.
  //-------------------------------------------------------------------------------
  const onConfirmPasswordResetCodeChanged = (event: any) => 
  {
    const code: string = event.target.value as string;
    setConfirmPasswordResetCode(code);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the "New Password" field.
  //-------------------------------------------------------------------------------
  const onNewPasswordChange = (event: any) => 
  {
    const newPwd: string = event.target.value as string;
    setNewPassword(newPwd);

    // Update the password validation indicators

    // At least 8 characters
    setPwdRule8Chars(newPwd.length >= 8);

    // 1 uppercase character (A-Z)
    // 1 lowercase character (a-z)
    // 1 numerical character (0-9)

    let numFound = false;
    let upperCaseFound = false;
    let lowerCaseFound = false;
    let specialCharFound = false;

    for(let i=0; i < newPwd.length; i++)
    {
      const c = newPwd.charAt(i);
  
      if(c >= 'a' && c <= 'z')
        lowerCaseFound = true;
      if(c >= 'A' && c <= 'Z')
        upperCaseFound = true;
      if(c >= '0' && c <= '9')
        numFound = true;
      if(COGNITO_PWD_RULE_SPECIAL_CHARS.includes(c))
        specialCharFound = true;
    }

    setPwdRule1UpChar(upperCaseFound);
    setPwdRule1LowChar(lowerCaseFound);
    setPwdRule1NumChar(numFound);
    setPwdRule1SpecialChar(specialCharFound);

    // The confirmation pwd must match the new pwd
    setPwdRuleConfirm(newPwd === confirmPassword);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the "Confirm Password" text field.
  //-------------------------------------------------------------------------------
  const onConfirmPasswordChange = (event: any) => 
  {
    const confirmPwd: string = event.target.value as string;
    setConfirmPassword(confirmPwd);

    // The confirmation pwd must match the new pwd
    setPwdRuleConfirm(confirmPwd === newPassword);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the "Email Address" text field.
  //-------------------------------------------------------------------------------
  const onEmailAddressChanged = (event: any) => 
  {
    const email: string = event.target.value as string;
    setUserEmail(email);
  }


  

  //-------------------------------------------------------------------------------
  // Main render - tells the user their password has been successfully changed.
  //-------------------------------------------------------------------------------

  if(processCompleteMode)
  return (

    <AccountPageBase>

      <Box component="form" sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0 }}>

        <img src={stratifyx_logo} alt="" height='150px' />

        <Typography sx={{ color: '#1b7837', mt: 3, mb: 3, fontSize: '1.5rem' }}>
          Your password has been changed.
        </Typography>

        {/* <Button variant="contained" sx={{ mt: 3, mb: 4 }} component={RouterLink} to={"/Account/LogIn"}>
          Log In
        </Button> */}

        <Link fontSize={20} href="/Account/LogIn" variant="body2" sx={{ mt: 2, mb: 5 }}>Proceed to Log In</Link>

      </Box>

    </AccountPageBase>
  )
  

  //-------------------------------------------------------------------------------
  // Main render - ask user to confirm code that was emailed
  //-------------------------------------------------------------------------------

  if(confirmCodeMode)
  return (

    <AccountPageBase minWidth='600px'>

      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0 }}>

        <img src={stratifyx_logo} alt="" height='150px' />

        <Typography sx={{ fontSize: '1.3rem', color: '#1976D2' }}>
          Please provide the code that was sent to you
        </Typography>

        {/* Confirm password reset code */}

        <TextField label="Password Reset Code" sx={{ mt: 3, mb: 4 }} autoFocus onChange={onConfirmPasswordResetCodeChanged} disabled={store_pwdResetRunningStep1} />

        <Typography sx={{ fontSize: '1.3rem', color: '#1976D2' }}>
          Please provide your new password
        </Typography>

        <Stack direction='row' sx={{ mt: 2 }}>

          {/* Left side - list all the pwd rules */}

          <Stack direction='column'>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <CheckIcon color={pwdRule8Chars?'success':'error'}/>
              <Typography sx={{ ml: 1.3, mt: 0.4, mb: 0.4, opacity: `${pwdRule8Chars?'0.4':'0.8'}`, fontSize: '0.9rem' }}>
                At least 8 characters
              </Typography>
            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
            <CheckIcon color={pwdRule1UpChar?'success':'error'}/>
            <Typography sx={{ ml: 1.3, mt: 0.4, mb: 0.4, opacity: `${pwdRule1UpChar?'0.4':'0.8'}`, fontSize: '0.9rem' }}>
                An uppercase character (A-Z)
              </Typography>
            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <CheckIcon color={pwdRule1LowChar?'success':'error'}/>
              <Typography sx={{ ml: 1.3, mt: 0.4, mb: 0.4, opacity: `${pwdRule1LowChar?'0.4':'0.8'}`, fontSize: '0.9rem' }}>
                A lowercase character (a-z)
              </Typography>
            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <CheckIcon color={pwdRule1NumChar?'success':'error'}/>
              <Typography sx={{ ml: 1.3, mt: 0.4, mb: 0.4, opacity: `${pwdRule1NumChar?'0.4':'0.8'}`, fontSize: '0.9rem' }}>
                A numerical character (0-9)
              </Typography>
            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <CheckIcon color={pwdRule1SpecialChar?'success':'error'}/>
              <Typography sx={{ ml: 1.3, mt: 0.4, mb: 0.4, opacity: `${pwdRule1SpecialChar?'0.4':'0.8'}`, fontSize: '0.9rem' }}>
                A special character
              </Typography>
              
              <Tooltip title='^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < &apos; : ; | _ ~ ` + ='>
                <HelpOutlineIcon color='info' sx={{ ml: '5px', opacity: 0.7, height: '20px' }} />
              </Tooltip>

            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <CheckIcon color={pwdRuleConfirm?'success':'error'}/>
              <Typography sx={{ ml: 1.3, mt: 0.4, mb: 0.4, opacity: `${pwdRuleConfirm?'0.4':'0.8'}`, fontSize: '0.9rem' }}>
                Confirmation matches
              </Typography>
            </Stack>

          </Stack>

          {/* Right side - the pwd change text boxes */}

          <Stack direction='column' sx={{ ml: 5 }}>

            {/* New password */}

            <TextField label="New Password" autoComplete='new-password' disabled={store_pwdResetRunningStep1}
                      sx={{ mt: 2 }} onChange={onNewPasswordChange} 
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" tabIndex={-1}
                              onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}/>

            {/* Confirm password */}

            <TextField label="Confirm New Password" type='password' sx={{ mt: 2 }} onChange={onConfirmPasswordChange} disabled={store_pwdResetRunningStep1}/>

          </Stack>

        </Stack>

        <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={(e) => OnPwdResetStep2Click()} disabled={store_pwdResetRunningStep1}>
          Change Password
        </Button>

        <Grid item xs={12} sx={{ mt: 2 }} >
          <Typography color='error.main' alignContent='center'>
            {errorMessage}
          </Typography>
        </Grid>

        <Link href="/" variant="body2" sx={{ mt: 2 }}>Back to App</Link>

      </Box>

    </AccountPageBase>
  )


  // Main render - initiate the password reset process (get email from user)

  return (

    <AccountPageBase>

      <Box component="form" sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0 }}>

        <img src={stratifyx_logo} alt="" height='150px' />

        {/* <Typography sx={{ color: theme_bgColorLight, mb: 3, fontSize: '1.6rem' }}>
          Reset Password
        </Typography> */}

        <Typography sx={{ color: '#1976D2', mt: 1, fontSize: '1.2rem' }}>
          A password reset code will be sent via text or to your registered email address.
        </Typography>

        <TextField fullWidth label="Email Address" sx={{ mt: 5, mb: 4 }} autoFocus
                   onChange={onEmailAddressChanged} disabled={store_pwdResetRunningStep2}
                   onKeyDown={(e) => { if(e.key === 'Enter') e.preventDefault(); OnPwdResetStep1Click(); }}/>

        <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={(e) => OnPwdResetStep1Click()} disabled={store_pwdResetRunningStep2}>
          Initiate Password Reset
        </Button>

        <Typography color='error.main' alignContent='center'>
          {errorMessage}
        </Typography>

        <Link href="/" variant="body2" sx={{ mt: 4 }}>Back to App</Link>

      </Box>

    </AccountPageBase>
  )
}  
